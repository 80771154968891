import React from 'react';
import { Icon } from '../icon';
import cn from 'classnames';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children: React.ReactNode;
  customHeader?: React.ReactNode;
  title?: string;
};

export const PopUpModal = ({ isOpen, setIsOpen, children, title }: Props) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <div className="flex items-center justify-center absolute top-0 right-0 left-0 bottom-0 p-0 md:p-4 z-100 h-screen">
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-charcoal-100 opacity-80 z-50 h-full"></div>
      <div className="fixed bg-artichoke-100 text-lemon-100 md:rounded-xl z-50 w-1/3 h-full md:h-auto overflow-y-auto max-h-screen">
        <>
          <div className="flex px-4 py-4 float-right">
            <div onClick={() => setIsOpen(false)} className="flex-shrink">
              <Icon icon="cross" className="hover:cursor-pointer" width={28} height={28} />
            </div>
          </div>
        </>
        <div className="px-6 pt-12 mb-4 w-1/2">
          <header className="text-3xl font-bold">{title}</header>
        </div>
        <div className={cn('px-6 pb-4 mr-4')}>{children}</div>
      </div>
    </div>
  );
};
