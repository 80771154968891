import React from 'react';
import { Page } from '@when-fertility/shared/domain/common';
import CalendlyApp from './components/calendly-component';

export const PatientsPage = () => {
  return (
    <Page title="Patients" className="bg-white md:bg-silver-40" headerClassName="bg-silver-100 md:bg-transparent pb-8">
      <div className="w-full space-y-8">
        <div className="p-3">
          <CalendlyApp />
        </div>
      </div>
    </Page>
  );
};
