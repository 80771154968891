import React from 'react';
import './terms-and-conditions.css';

export const TermsAndConditionsContentComponent = () => {
  return (
    <div>
      <div>
        <p className="c16 c11">
          <span className="c5 c14"></span>
        </p>
      </div>
      <p className="c33">
        <span className="c5 c15">When Fertility Terms &amp; Conditions</span>
      </p>
      <h1 className="c22" id="h.gjdgxs">
        <span className="c13 c1 c6">Terms &amp; Conditions</span>
      </h1>
      <ol className="c0 lst-kix_list_3-0 start" start="1">
        <li className="c2 li-bullet-0">
          <span className="c13 c1 c6">Introduction</span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1 start" start="1">
        <li className="c4 c10 li-bullet-1" id="h.30j0zll">
          <span className="c1">WHEN Fertility Pty Ltd (ACN 654 291 442, ABN 88 654 291 442) (</span>
          <span className="c1 c6">WHEN Fertility, we, us, our</span>
          <span className="c1">) operates a digital health platform which provides products and services through the WHEN Fertility website (</span>
          <span className="c1 c6">Website</span>
          <span className="c5 c1">) as well as other distribution channels. </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">At WHEN Fertility we:</span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-2 start" start="1">
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">create and link customers to information about egg count; </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">provide sample collection kits; and</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">provide a link between our customers and: </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-3 start" start="1">
        <li className="c4 c36 li-bullet-1">
          <span className="c1">pathology providers (</span>
          <span className="c1 c6">Partner Providers</span>
          <span className="c5 c1">); and</span>
        </li>
        <li className="c4 c36 li-bullet-1">
          <span className="c1">registered Australian health practitioners, including medical practitioners and nurses (</span>
          <span className="c1 c6">Practitioner Providers</span>
          <span className="c5 c1">).</span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-1" start="3">
        <li className="c4 c10 li-bullet-1" id="h.1fob9te">
          <span className="c1">Our Partner Providers and Practitioner Providers are collectively referred to as </span>
          <span className="c1 c6">Providers</span>
          <span className="c5 c1">&nbsp;in these Terms and Conditions.</span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c1">Throughout these Terms and Conditions "</span>
          <span className="c1 c6">You</span>
          <span className="c1">" or "</span>
          <span className="c1 c6">you</span>
          <span className="c5 c1">
            " means, you the user or consumer that is accessing our Website, any Account or otherwise purchasing WHEN Fertility products or services.
          </span>
        </li>
      </ol>
      <p className="c4 c11 c37">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="2">
        <li className="c2 li-bullet-0">
          <span className="c13 c1 c6">Important information </span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="5">
        <li className="c4 c10 li-bullet-1">
          <span className="c13 c1 c6">
            It is important that you are aware that our Website’s content, test results and any communications you have with WHEN Fertility or our
            Providers are not a substitute for independent medical advice. You should always obtain medical advice from your regular medical
            practitioner.{' '}
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="6">
        <li className="c4 c10 li-bullet-1" id="h.3znysh7">
          <span className="c1 c6">The sample collection kit tests egg count only. It does not test egg quality. Indicated egg count does </span>
          <span className="c24 c1 c6">not</span>
          <span className="c1 c6">&nbsp;predict your chance of becoming pregnant, whether now or in the future. This test does </span>
          <span className="c1 c6 c24">not</span>
          <span className="c13 c1 c6">
            &nbsp;diagnose, treat, cure or prevent any disease or medical condition. Test results, including any comments with your test report or in
            a consultation with a Practitioner Provider, are not a substitute for independent medical advice. You should speak with a medical
            practitioner if you wish to assess your fertility and before making any decisions about healthcare, including contraception. For details
            on the science and use of information about egg count, please visit whenfertility.com.au/science
          </span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="3">
        <li className="c4 c19 li-bullet-2">
          <span className="c13 c1 c6">Acceptance</span>
        </li>
      </ol>
      <p className="c4 c12 c11 c27">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="7">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            By accessing, browsing or using the Website, you agree to these Terms and Conditions as amended from time to time, and acknowledge that
            you have read and understood these Terms and Conditions.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            These Terms and Conditions govern the use of the Website, the purchases of any goods or services from WHEN Fertility (whether through the
            Website or from any other channel) and the provision of any services by WHEN Fertility whether through the Website or otherwise.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            WHEN Fertility reserves the right to amend the Terms and Conditions at any time. If you are a registered user, you will be notified of
            these amendments by email. If you do not agree with the Terms and Conditions, you may unsubscribe and close your user account at any time
            (see paragraphs 39-41 below).{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.2et92p0">
          <span className="c5 c1">
            When creating a user account, you will be provided with these Terms and Conditions and our Privacy Policy in a pop up box and you will be
            required to read and agree to both before your account creation is finalised.{' '}
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="4">
        <li className="c2 li-bullet-0">
          <span className="c13 c1 c6">User account</span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="11">
        <li className="c4 c10 li-bullet-1">
          <span className="c1">You are only permitted to create one registered user account (</span>
          <span className="c1 c6">Account</span>
          <span className="c5 c1">).</span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">An Account is required to:</span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-2 start" start="1">
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">activate your sample collection kit; </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">
            complete the process for submitting a sample obtained after purchase of a WHEN Fertility sample collection kit;
          </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">create your profile;</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">see the progress of the analysis of your sample;</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">access the test results from the analysis of your sample; and</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">if relevant, attend appointments with Practitioner Providers. </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-1" start="13">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            To create an Account, you must access www.member.whenfertility.com.au/register and fill in your details. You must then confirm that you
            agree to these Terms and Conditions and our Privacy Policy and click the “create account” button.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            On registration, you are required to create a password for your Account. It is your responsibility to remember your password and keep it
            confidential and secure.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c1">
            If you suspect your Account has been accessed or used without your authority, you must immediately change your password and immediately
            notify WHEN Fertility at{' '}
          </span>
          <span className="c9 c1">
            <a className="c20" href="mailto:hello@whenfertility.com.au">
              hello@whenfertility.com.au
            </a>
          </span>
          <span className="c5 c1">. This is important so that your personal information remains secure. </span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.tyjcwt">
          <span className="c5 c1">
            WHEN Fertility is not liable for any damages, losses or expenses suffered by you as a result of any unauthorised access to a user's
            Account except to the extent that such damages, losses or expenses are incurred as a result of fraud, wilful misconduct or negligent acts
            or omissions of WHEN Fertility.{' '}
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="5">
        <li className="c2 li-bullet-0">
          <span className="c13 c1 c6">Sample collection and testing</span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c1 c6 c13"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="17">
        <li className="c4 c10 li-bullet-1">
          <span className="c1 c23">A sample collection kit may be purchased from the Website or other sources. </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            Any valid discount codes must be applied at the time of purchase as WHEN Fertility cannot apply them retrospectively.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">WHEN Fertility will dispatch your order to the address you have provided. </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">In order to activate your sample collection kit you must create an Account. </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">Always read the label and follow the directions for use. &nbsp;</span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.3dy6vkm">
          <span className="c5 c1">
            If you do not follow the steps stated on the instructions that are contained within your sample collection kit to collect your sample
            correctly, WHEN Fertility and the Partner Providers will not be able to warrant the accuracy of your results. WHEN Fertility and the
            Partner Providers will not be responsible for any inaccuracy in results caused by incorrect sample collection other than where WHEN
            Fertility and/or the Partner Provider have made a negligent act or omission which contributed to that inaccuracy and in that case, WHEN
            Fertility and/or the Partner Provider's liability will be reduced to the extent of their negligence.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c1">
            Our Partner Provider is an independent third party engaged by WHEN Fertility. You must use the reply paid envelope included in your sample
            collection kit to post the sample to our Partner Provider to ensure your sample is received in a timely manner. If you misplace your reply
            paid envelope, do not use your own envelope. Please notify WHEN Fertility at{' '}
          </span>
          <span className="c9 c1">
            <a className="c20" href="mailto:hello@whenfertility.com.au">
              hello@whenfertility.com.au
            </a>
          </span>
          <span className="c5 c1">
            &nbsp;as soon as possible to arrange a replacement reply paid envelope which will be sent to you free of charge.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">Once the Partner Provider receives your sample, the Partner Provider will conduct testing on the sample. </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            WHEN Fertility will send you an email to notify you when your test results are available. You will be able to see the progress of the
            analysis of your sample and access your test results through your member portal which you access by logging into your Account. &nbsp;
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c1">If you have not received your results within 10</span>
          <span className="c1 c6">&nbsp;</span>
          <span className="c1">days, please contact WHEN Fertility at </span>
          <span className="c9 c1">
            <a className="c20" href="mailto:hello@whenfertility.com.au">
              hello@whenfertility.com.au
            </a>
          </span>
          <span className="c9 c1">&nbsp;</span>
          <span className="c1">with details of when your sample was sent.</span>
          <span className="c3">&nbsp; </span>
          <span className="c5 c1">
            If your sample is lost due to the fault of WHEN Fertility or our Partner Providers, we will send you a new sample collection kit free of
            charge.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            If your results indicate a low egg count, you will be provided with instructions at the end of your test report to book an appointment
            with a Practitioner Provider to discuss your results. This appointment is at no additional cost to you.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            If you wish to investigate your results further or if your results indicate a low egg count but you choose not to attend an appointment
            with a Practitioner Provider, it is important that you speak to your medical practitioner to obtain further advice.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">The appointment with our Practitioner Provider can be booked through the member portal. </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            Your appointment will last up to 20 minutes, with the length depending on your test results and the topics discussed.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            WHEN Fertility will send you a SMS reminder 24 hours prior to your appointment. WHEN Fertility will also send you a second SMS reminder 3
            hours prior to your appointment.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.1t3h5sf">
          <span className="c5 c1">
            If you fail to attend your appointment or if the appointment does not proceed or finish due to issues with your connection:
          </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-2 start" start="1">
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">on one occasion, you will be given the option to reschedule your appointment; and</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">
            if it is the second time you have failed to attend your appointment for any reason and without providing the required notice pursuant to
            paragraph 33(a), your free of charge appointment will be forfeited and you will need to attend a meeting with your own medical
            practitioner.{' '}
          </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-1" start="33">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            If you are no longer able to attend your appointment, please let us know at least 2 hours prior to your appointment by emailing
            hello@whenfertility.com.au &nbsp;
          </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-2 start" start="1">
        <li className="c4 c7 li-bullet-1" id="h.4d34og8">
          <span className="c5 c1">If you provide us with at least 2 hours of notice, you will have the option to reschedule your appointment. </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">
            If you provide us with less than 2 hours of notice, this will be treated as though you failed to attend your appointment per paragraph 32
            above.{' '}
          </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-1" start="34">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            If the Practitioner Provider fails to attend the appointment or if the appointment does not proceed or finish due to the Practitioner
            Provider or WHEN Fertility's fault, you will be given the option to reschedule your appointment.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.2s8eyo1">
          <span className="c1 c6">
            Test results, including any comments with your test report or in a consultation with a Practitioner Provider, are{' '}
          </span>
          <span className="c24 c1 c6">not</span>
          <span className="c13 c1 c6">
            &nbsp;a substitute for independent medical advice. You should speak with a medical practitioner if you have any questions arising
            following the call with our Practitioner Provider, if you wish to assess your fertility and before making any decisions about healthcare,
            including contraception.{' '}
          </span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="6">
        <li className="c2 li-bullet-3">
          <span className="c13 c1 c6">Personal information</span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="36">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            When you create an Account, you will be required to provide WHEN Fertility with personal information through the account creation process,
            including (but not limited to) identity information, including your name, phone number, address and email address.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            The WHEN Fertility Privacy Policy can be accessed via www.whenfertility.com.au/privacy-policy, and sets out how we collect, store and use
            your personal information and sensitive information.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.17dp8vu">
          <span className="c5 c1">
            You agree that all information you provide to WHEN Fertility and the Providers will be accurate, up-to-date and complete. You agree that
            you will not omit any information that is reasonably relevant to the products and services provided through the Website and any other
            distribution channel. Should any information you have provided to WHEN Fertility or the Providers be inaccurate or become inaccurate, you
            agree that you will correct that information as soon as reasonably possible.{' '}
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="7">
        <li className="c2 li-bullet-0">
          <span className="c13 c1 c6">Closing your Account and unsubscribing</span>
        </li>
      </ol>
      <p className="c4 c11 c12">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="39">
        <li className="c4 c10 li-bullet-1" id="h.3rdcrjn">
          <span className="c1">If at any time you wish to close your Account you should email </span>
          <span className="c9 c1">
            <a className="c20" href="mailto:hello@whenfertility.com.au">
              hello@whenfertility.com.au
            </a>
          </span>
          <span className="c5 c1">&nbsp;with details of your request including your name, date of birth, address and email address. </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">Your Account will be closed within 30 days. </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            You may subscribe to WHEN Fertility information alerts by creating an Account or by providing us with your email address through the
            Website. If at any time you wish to unsubscribe from WHEN Fertility information alerts, please click the 'unsubscribe' button at the
            bottom of any WHEN Fertility email.
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="8">
        <li className="c2 li-bullet-3">
          <span className="c13 c1 c6">Prices, fees and payment</span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="42">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            Prices for all WHEN Fertility goods and services are displayed on the Website and are current at the time of issue. Prices and
            availability of the goods and services may change from time to time.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            You are responsible for any taxes, duties or other liabilities imposed by any government or agency, including without limitation, any
            customs duty, goods and services taxes or any value added tax imposed on any goods or services acquired or ordered by you from the
            Website.{' '}
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="9">
        <li className="c2 li-bullet-4">
          <span className="c13 c1 c6">Intellectual Property Rights</span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="44">
        <li className="c4 c10 li-bullet-1" id="h.26in1rg">
          <span className="c5 c1">Unless otherwise indicated, Intellectual Property Rights in:</span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-2 start" start="1">
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">
            &nbsp;all materials or media in any form on the Website including the text, information, graphics, logos and design;
          </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">the sample collection process and product; </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">test reports or results;</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">any instructions, materials, designs or labelling of the sample collection kit;</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">any educational materials or media;</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">any other products and services provided by WHEN Fertility,</span>
        </li>
      </ol>
      <p className="c4 c38">
        <span className="c1">are owned by or licensed to WHEN Fertility IP Pty Ltd (</span>
        <span className="c1 c6">WHEN IP Rights</span>
        <span className="c5 c1">). </span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="45">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            You must not copy, exploit, adapt, reproduce, store, transmit, distribute, sell, modify, publish, print or create derivative works of the
            whole or any part of the WHEN IP Rights for any purpose whatsoever without the prior written consent of WHEN Fertility IP Pty Ltd or, in
            the case of third party material or media, from the owner of the Intellectual Property Rights in that material or media. However, you may
            provide a copy of your test report to show your medical practitioner.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.lnxbz9">
          <span className="c5 c1">
            WHEN is a trade-mark of When Fertility IP Pty Ltd. &nbsp;All other trade marks used on the Website are trade marks of respective third
            party owners.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            Nothing contained on the Website should be construed as granting any licence or right to use any trade mark displayed on the Website
            without WHEN Fertility IP Pty Ltd's express permission, or permission from the third party owner of the respective trade mark. All third
            party trade marks used on the Website are used with the consent of the third party owners and does not include a licence for you to use
            the trade marks.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c1">Please contact WHEN Fertility IP Pty Ltd using the contact form on the Website or by emailing </span>
          <span className="c9 c1">
            <a className="c20" href="mailto:hello@whenfertility.com.au">
              hello@whenfertility.com.au
            </a>
          </span>
          <span className="c5 c1">&nbsp;with any requests to use materials or media available on the Website.</span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.35nkun2">
          <span className="c1">In these terms and conditions, </span>
          <span className="c1 c6">Intellectual Property Rights</span>
          <span className="c1">
            &nbsp;means all present and future rights conferred by law in or in relation to copyright, trade marks, designs, patents, circuit layouts,
            plant varieties, business and domain names, inventions and confidential information, and other results of intellectual activity in the
            industrial, commercial, scientific, literary or artistic fields
          </span>
          <span className="c1 c6">&nbsp;</span>
          <span className="c5 c1">whether or not registrable, registered or patentable.</span>
        </li>
      </ol>
      <p className="c4 c18">
        <span className="c5 c1">These rights include:</span>
      </p>
      <ol className="c0 lst-kix_list_1-2" start="7">
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">all rights in all applications to register these rights;</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">all renewals and extensions of these rights; and</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">all rights in the nature of these rights, excluding moral rights. </span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="10">
        <li className="c2 li-bullet-0" id="h.1ksv4uv">
          <span className="c13 c1 c6">Third Party Content </span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="50">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            The Website may contain links to or display the content of third parties, including links to websites operated by third parties. Where
            WHEN Fertility is displaying or linking to third party content, this will be made clear on the Website.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.44sinio">
          <span className="c5 c1">
            WHEN Fertility does not control these linked websites and content. WHEN Fertility provides these links and content for convenience only,
            and does not endorse, support or approve or make any warranty or claim regarding them, the products, services or information available on
            these third party websites, the owner or operator of these third party websites, or their conduct.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            In the event you have any concerns about any content or links on our Website please contact WHEN Fertility by emailing
            hello@whenfertility.com.au{' '}
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="11">
        <li className="c2 c27 li-bullet-4">
          <span className="c13 c1 c6">Conduct</span>
        </li>
      </ol>
      <p className="c4 c12 c27 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="53">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">You must not use the Website or your Account:</span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-2 start" start="1">
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">&nbsp;in a way that breaches any applicable laws or regulations;</span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">for commercial purposes; or </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">to harm, abuse, harass, stalk, threaten or otherwise offend others.</span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-1" start="54">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            You must not use the Website or your Account to interfere with, disrupt, or create an undue burden on the Website or your Account or
            attempt to transmit any material of any kind to the Website or your Account which contains a virus, malicious computer code or another
            harmful component.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">Without limiting any of the above conditions, you will not and will not permit a third party to:</span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-2 start" start="1">
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">
            link to, frame, mirror or use any data extraction tools on any part of the Website or your Account except with WHEN Fertility's written
            authorisation;{' '}
          </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">
            use any product, method or process (including but not limited to data scraping, web-bots, collection or accumulation tools, robots,
            spiders or scripted responses) in order to obtain, process, copy, replicate, distribute, reconfigure, republish, view, assess, analyse,
            modify or replace the whole or any part of the Website or your Account;
          </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">
            use, obtain, or attempt to use or obtain from the Website or your Account, information in relation to business methodology or systems; and
          </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">
            do anything which will or may cause damage, disruption or interference with the operation of the Website or any Account.
          </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-1" start="56">
        <li className="c4 c10 li-bullet-1">
          <span className="c1">If you believe that a user has breached any of the above conditions, please contact us at </span>
          <span className="c1 c9">
            <a className="c20" href="mailto:hello@whenfertility.com.au">
              hello@whenfertility.com.au
            </a>
          </span>
          <span className="c5 c1">.</span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="12">
        <li className="c2 li-bullet-4">
          <span className="c13 c1 c6">Breach </span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="57">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            If you breach any provision of these Terms and Conditions, WHEN Fertility will notify you of that breach. If the breach is not rectified
            within 7 days of notification, WHEN Fertility may block or suspend your Account, and modify or remove any material uploaded, posted,
            transmitted or otherwise made available by you on the Website or through your Account.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            If the breach damages, disrupts or interferes with the operation of the Website, any Account or disrupts other users' use of the Website
            or any Account, WHEN Fertility reserves the right to block or suspend your account, and to modify or remove any material uploaded, posted,
            transmitted or otherwise made available by you on the Website or through your Account, without notice.{' '}
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="13">
        <li className="c2 li-bullet-4">
          <span className="c13 c1 c6">Indemnity and liability</span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="59">
        <li className="c4 c10 li-bullet-1" id="h.2jxsxqh">
          <span className="c5 c1">
            WHEN Fertility is not liable for any claims, loss, damage, tax, liability and/or expense arising from or in connection with your, or any
            other customer's, breach or failure to comply with these Terms and Conditions except to the extent that the claims, loss, damage, tax,
            liability and/or expense arises as a result of WHEN Fertility's fraud, wilful misconduct or negligent acts or omissions.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            WHEN Fertility is not liable for any material uploaded, posted, transmitted or otherwise made available on the Website or in any Account
            by any person other than WHEN Fertility. WHEN Fertility does not endorse any opinion, advice or statement made by any person other than
            WHEN Fertility.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            To the extent permitted by law, WHEN Fertility is not liable for any loss or damage, howsoever caused, which may result from your use of
            or failure to access the Website or your Account, the content contained on the Website or in your Account or due to the fact that the
            Website or any Account content is wrong, incomplete or out of date except to the extent that the loss or damage arises as a result of WHEN
            Fertility's fraud, wilful misconduct or negligent acts or omissions.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.z337ya">
          <span className="c5 c1">
            To the extent permitted by law, WHEN Fertility disclaims any liability arising from your use of our products and services except to the
            extent the liability arises as a result of WHEN Fertility's fraud, wilful misconduct or negligent acts or omissions.
          </span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="14">
        <li className="c2 li-bullet-4">
          <span className="c13 c1 c6">Warranty and Disclaimer</span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="63">
        <li className="c4 c10 li-bullet-1" id="h.3j2qqm3">
          <span className="c5 c1">
            By accessing our Website and/or your Account, you assume all risks associated with its use, including but not limited to the risk that
            your computer, software or data may be damaged by any virus transmitted by the Website or by any third party content (as described in
            paragraph ‎J above), except to the extent that any damage or risks arise as a result of WHEN Fertility's fraud, wilful misconduct or
            negligent acts or omissions.
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="15">
        <li className="c2 li-bullet-0">
          <span className="c13 c1 c6">Jurisdiction</span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="64">
        <li className="c4 c10 li-bullet-1" id="h.1y810tw">
          <span className="c5 c1">These Terms and Conditions are governed by the laws of New South Wales, Australia.</span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="16">
        <li className="c2 li-bullet-3">
          <span className="c13 c1 c6">Delivery</span>
        </li>
      </ol>
      <p className="c4 c11 c32">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="65">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">WHEN Fertility will retain title to the goods you order until you have made payment in full for those goods.</span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">WHEN Fertility will dispatch your goods once we have received your payment in full.</span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            If delivery times are published on the Website or otherwise provided, these are estimates and are indicative only. WHEN Fertility is not
            liable for any failure to observe these delivery times except to the extent that any delay or damage arises as a result of WHEN
            Fertility's fraud, wilful misconduct or negligent acts or omissions.{' '}
          </span>
        </li>
      </ol>
      <p className="c4 c11 c18">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="17">
        <li className="c2 li-bullet-0">
          <span className="c13 c1 c6">WHEN Fertility Cancellation, Returns and Refunds Policy</span>
        </li>
      </ol>
      <p className="c4 c12 c11">
        <span className="c13 c1 c6"></span>
      </p>
      <ol className="c0 lst-kix_list_1-1" start="68">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            WHEN Fertility is not required to cancel your order, provide a refund or provide a replacement if you change your mind. However, if your
            product has not been dispatched yet, please contact WHEN Fertility via hello@whenfertility.com.au and we may be able facilitate a change
            of mind refund.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            WHEN Fertility will provide you with a refund or replacement if the goods or services we provide do not meet the consumer guarantees in
            the Australian Consumer Law, including if:
          </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-2 start" start="1">
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">
            the goods we provide are not of acceptable quality, including if they are not fit for purpose or if there are defects; or{' '}
          </span>
        </li>
        <li className="c4 c7 li-bullet-1">
          <span className="c5 c1">the services we provide are not rendered with due care and skill or are not fit for purpose. </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-1" start="70">
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            Subject to rights that are guaranteed or cannot be waived under Australian Consumer Law, we are not able to issue returns and refunds for
            any other reason due to the medical nature of our product.{' '}
          </span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c5 c1">
            If you would like to request a cancellation or refund, or you think your product is faulty or has been damaged in transit, please contact
            hello@whenfertility.com.au and we will be happy to assist.
          </span>
        </li>
      </ol>
      <p className="c4 c18 c11">
        <span className="c5 c1"></span>
      </p>
      <ol className="c0 lst-kix_list_3-0" start="18">
        <li className="c2 li-bullet-4">
          <span className="c13 c1 c6">Operation of these Terms and Conditions</span>
        </li>
      </ol>
      <ol className="c0 lst-kix_list_1-1 start" start="1">
        <li className="c4 c10 li-bullet-1" id="h.4i7ojhp">
          <span className="c5 c1">
            Any provision of these Terms and Conditions, which is unenforceable or partly unenforceable is, where possible, to be severed to the
            extent necessary to make these Terms and Conditions enforceable, unless this would materially change the intended effect of these Terms
            and Conditions.
          </span>
        </li>
        <li className="c4 c10 li-bullet-1" id="h.2xcytpi">
          <span className="c5 c1">
            The obligations in paragraphs ‎1 to 10, 16, 22, 35 to 38, 44 to 46, 49 to 51, 59 to 62, 63 to 64 and 72 to 73 of these Terms and
            Conditions survive any termination or expiry of this document.
          </span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1 c39"></span>
      </p>
      <p className="c4">
        <span className="c1">If you have any queries regarding these Terms and Conditions, please contact us at </span>
        <span className="c21 c1">
          <a className="c20" href="mailto:hello@whenfertility.com.au">
            hello@whenfertility.com.au
          </a>
        </span>
      </p>
      <p className="c4 c11">
        <span className="c21 c23 c1 c29"></span>
      </p>
      <p className="c28">
        <span className="c5 c3">Policy Number: WF007.v3</span>
      </p>
      <p className="c28 c11">
        <span className="c5 c3"></span>
      </p>
      <p className="c11 c28">
        <span className="c5 c3"></span>
      </p>
      <p className="c28 c11">
        <span className="c5 c3"></span>
      </p>
      <p className="c33">
        <span className="c5 c15">WHEN Fertility Privacy Policy </span>
      </p>
      <p className="c11 c30">
        <span className="c5 c1"></span>
      </p>
      <h3 className="c17" id="h.vg3q7wqkypbi">
        <span className="c13 c1 c6">1. Introduction</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">
          1.1 WHEN is committed to protecting the privacy and rights of individuals when it comes to their personal information. We appreciate that
          you are trusting us with your personal information when you use WHEN Fertility and we take this responsibility seriously.
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">1.2 At WHEN Fertility we:</span>
      </p>
      <ol className="c0 lst-kix_lxmvnmtqd0jr-0 start" start="1">
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">create and link customers to information about various aspects of fertility, including egg count; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">provide sample collection kits; and</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">provide: </span>
        </li>
      </ol>
      <ol className="c0 lst-kix_lxmvnmtqd0jr-1 start" start="1">
        <li className="c4 c10 li-bullet-1">
          <span className="c1">services to connect our clients and Australian registered health providers such as pathology providers (</span>
          <span className="c1 c6">Partner Providers</span>
          <span className="c5 c1">)</span>
        </li>
        <li className="c4 c10 li-bullet-1">
          <span className="c1">
            services to connect our clients and our Australian registered health practitioners, including medical practitioners and nurses (
          </span>
          <span className="c1 c6">Practitioner Providers</span>
          <span className="c5 c1">)</span>
        </li>
      </ol>
      <p className="c4 c18">
        <span className="c1">(collectively, </span>
        <span className="c1 c6">Providers</span>
        <span className="c5 c1">)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c1">1.3 We have a legal obligation to abide by the provisions of the Privacy Act 1988 (Cth) (</span>
        <span className="c1 c6">Privacy Act</span>
        <span className="c1">), which include the Australian Privacy Principles (</span>
        <span className="c1 c6">APPs</span>
        <span className="c5 c1">). We are also bound by any relevant health privacy principles under State legislation.</span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          1.4 Please take a moment to read this policy, as it outlines the types of information we collect, why we collect it and how you can manage,
          update and delete the information we hold.{' '}
        </span>
      </p>
      <h3 className="c17">
        <span className="c13 c1 c6">2. Terminology</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">2.1 In this privacy policy:</span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c1 c6">personal information</span>
        <span className="c5 c1">
          &nbsp;means information or an opinion about an identified individual, or an individual who is reasonably identifiable:
        </span>
      </p>
      <ol className="c0 lst-kix_yplpvhewoxry-0 start" start="1">
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">whether the information or opinion is true or not; and</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">whether the information or opinion is recorded in a material form or not.</span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c1 c6">sensitive information</span>
        <span className="c5 c1">
          &nbsp;means personal information that is information or an opinion about an individual's racial or ethnic origin, political opinions,
          membership of a political association, religious beliefs or affiliations, philosophical beliefs, membership of a professional or trade
          association, membership of a trade union, sexual orientation or practices, or criminal record. Sensitive information also includes health
          information, genetic information, biometric information, and biometric templates.{' '}
        </span>
      </p>
      <p className="c4 c11">
        <span className="c1 c5"></span>
      </p>
      <p className="c4">
        <span className="c1 c6">WHEN Fertility</span>
        <span className="c1">, </span>
        <span className="c1 c6">we</span>
        <span className="c1">, </span>
        <span className="c1 c6">us</span>
        <span className="c1">, </span>
        <span className="c1 c6">our</span>
        <span className="c5 c1">&nbsp;means WHEN Fertility Pty Ltd (ACN: 654 291 442)</span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c1 c6">you </span>
        <span className="c1">and </span>
        <span className="c1 c6">your</span>
        <span className="c5 c1">&nbsp;means each and every individual whose personal information we may handle from time to time.</span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <h3 className="c17" id="h.30j0zll-1">
        <span className="c13 c1 c6">3. How we collect your personal information</span>
      </h3>
      <p className="c4">
        <span className="c1">
          3.1 We will collect and hold your personal information in a lawful and fair manner, and only when it is reasonably necessary for one or more
          of our functions or activities.{' '}
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c25"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">3.2 Where it is reasonably practical to do so, we will collect your personal information directly from you.</span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">3.3 We collect your personal information directly from you when you:</span>
      </p>
      <ol className="c0 lst-kix_ikuk634edow9-0 start" start="1">
        <li className="c4 c8 li-bullet-1">
          <span className="c1">visit our Website; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">sign up for our marketing communications;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">make an inquiry through our Website;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">attend one of our promotional events;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">complete an order in relation to goods or services;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">attend an appointment with one of our Providers;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">contact us via telephone;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">correspond with us, including through our Website, in writing or via email;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">interact with us on social media platforms;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">provide your personal information through any of our mobile applications; and/or</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">fill out a customer satisfaction or market research survey.</span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">3.4 We may also collect your personal information:</span>
      </p>
      <ol className="c0 lst-kix_f3r5o9u9gdu9-0 start" start="1">
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">from our group companies in order to provide you with goods and services;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">from our Providers when they provide goods and services to you;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">from publicly available sources; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">from third parties (for example, suppliers, recruitment agencies, contractors, and business partners);</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">from government sources, government authorities or databases relevant to the provision of a service to you (</span>
          <span className="c1 c6">Government Sources</span>
          <span className="c1">); and</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">from any other people or entities involved in assisting us with providing our services to you.</span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          3.5 In circumstances where we collect your personal information from a third party, we will, where appropriate, ask the third party to
          inform you that we have collected and are holding your information, how we will use and disclose it, and how you may contact us to access,
          correct and/or update your information.
        </span>
      </p>
      <h3 className="c17" id="h.1fob9te-2">
        <span className="c13 c1 c6">4. Types of personal information we collect</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">4.1 The types of personal information we collect include (but are not limited to): </span>
      </p>
      <ol className="c0 lst-kix_ys6j0jd88rev-0 start" start="1">
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">Identify information, including your name, gender, date of birth; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">Contact information, including postal address, email address, contact phone numbers; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">Financial information, including billing and shipping information;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">
            Transaction-specific information, including details of the goods and services you make enquiries about, details of the goods and services
            you purchase from us, written consents related to the goods and services we provide.
          </span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          4.2 We only collect sensitive information about you with your consent, or otherwise in accordance with the Privacy Act. The types of
          sensitive information we collect include (but are not limited to): your medical history, lifestyle history, demographic information, height,
          weight, family status, family planning intentions, medical records, medical prescriptions, laboratory results, and laboratory results
          commentary. We will only collect sensitive information when it is necessary to provide our goods and services to you.
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          4.3 You may choose to withhold your personal information, however if you choose to, we may not be able to provide you with the requested
          goods or services.
        </span>
      </p>
      <h3 className="c17" id="h.3znysh7-3">
        <span className="c13 c1 c6">5. Our purposes for handling your personal information</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">5.1 We collect, hold, use and disclose personal information to:</span>
      </p>
      <ol className="c0 lst-kix_j92y1nrorbj-0 start" start="1">
        <li className="c4 c8 li-bullet-1">
          <span className="c1">offer and provide you with our goods and services;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">facilitate and authorise payment and processing via third party gateways;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">communicate with you about the provision of our goods and services;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">
            provide you with announcements and updates, support and administrative messages and to provide you with information upon your request;
          </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">
            send you marketing and promotional information about us and our goods and services; and our group companies and business partners that
            provide goods and services of interest;
          </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">facilitate third parties communicating with you about our goods or services that might be of interest to you;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">
            improve your experience with our goods and services, or to improve our, or any of our group companies’ products and services, including
            through research and development;
          </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">responding to your issues, complaints, or enquiries; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">
            disclosure to law enforcement bodies and regulatory authorities in connection with investigations and compliance with law; and
          </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">as otherwise required or authorised by law. </span>
        </li>
      </ol>
      <h3 className="c17" id="h.2et92p0-4">
        <span className="c13 c1 c6">6. Disclosure of personal information</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">6.1 We will only disclose personal information when it is in accordance with the Privacy Act and the APPs.</span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">6.2 Specifically, we will disclose your personal information to:</span>
      </p>
      <ol className="c0 lst-kix_s8s4bw91u5cp-0 start" start="1">
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">Partner Providers; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">Practitioner Providers; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">our contractors and group companies; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">Government Sources where required by law; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">cloud service providers; </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">payment system operators;</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">to our service providers; and</span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c1">
            emergency services to request assistance in circumstances where &nbsp;a Practitioner Provider forms the professional opinion that you are
            at risk of imminent harm, and that Practitioner Provider has a professional and ethical obligation to do so.
          </span>
        </li>
      </ol>
      <p className="c4 c26">
        <span className="c5 c1">&nbsp; &nbsp; &nbsp;</span>
      </p>
      <p className="c4">
        <span className="c1">
          6.3 We will not use or disclose your personal information for any other purpose unless you have consented to that use or disclosure or where
          we are required or authorised by law.
        </span>
      </p>
      <h3 className="c17" id="h.tyjcwt-5">
        <span className="c13 c1 c6">7. Retention and protection of personal information</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">
          7.1 We will only retain your personal information for as long as reasonably necessary to fulfil the purposes we collected it for, including
          for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements.{' '}
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          7.2 To determine the appropriate retention period for personal information, we consider the amount, nature and sensitivity of the personal
          information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purposes for which we process
          your personal information and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax,
          accounting or other requirements.
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          7.3 We will hold personal information electronically on our intranet system and in cloud storage. We may also hold your personal information
          on third party servers which may be located overseas, including servers located in the USA, Europe and Asia. We maintain appropriate
          security for our offices and information storage facilities so as to prevent any loss, misuse, unauthorised access, disclosure, or
          modification of personal information.{' '}
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          7.4 We restrict access to your personal information to only those who need access to the personal information to do their job. Physical,
          electronic and managerial procedures have been employed to safeguard the security and integrity of your personal information.
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          7.5 We will destroy or de-identify personal information once it is no longer needed for a valid purpose, and we are not required to keep it
          by law.
        </span>
      </p>
      <h3 className="c17" id="h.3dy6vkm-6">
        <span className="c13 c1 c6">8. Direct marketing</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">
          8.1 We will only engage in direct marketing in accordance with the requirements of the Privacy Act and other applicable laws, including
          where:
        </span>
      </p>
      <ol className="c0 lst-kix_gn0wvmapmitf-0 start" start="1">
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">you have consented to receive direct marketing materials; or </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">we are otherwise permitted by law to send you direct marketing materials. </span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          8.2 From time to time, we may use or disclose your personal information to our third-party service providers to provide you with marketing
          materials that may be of interest to you, including marketing materials in relation to offers, specials, other products and services that
          we, our group companies, or affiliated business partners provide.
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          8.3 Except to the extent that our collection, use or disclosure is subject to clause 5.1(a) and 5.1(c), we will not collect, use or disclose
          your sensitive information for the purpose of direct marketing without your consent.{' '}
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          8.4 You may unsubscribe from our marketing communications at any time. If you would like to unsubscribe, you can do so by following the
          instructions provided in the relevant marketing communications or by contacting our Privacy Officer. If you are receiving marketing
          communications from us through more than one channel (eg email and SMS), you will need to unsubscribe from each channel individually by
          following the instructions provided along with the communication or by contacting our Privacy Officer at hello@whenfertility.com.au
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          8.5 Receiving marketing communications is not a condition of any purchase or service with us. If your contact details change, you may need
          to subscribe with your new contact details to ensure you can still receive marketing communications.
        </span>
      </p>
      <h3 className="c17" id="h.1t3h5sf-7">
        <span className="c13 c1 c6">9. Cookies</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">
          9.1 We may automatically collect certain information about your use of our services through cookies and other tracking technologies. Cookies
          enable us to compile aggregate statistics concerning use of our services, analyse trends, enhance the security of our services, deliver
          content and advertisements (where permitted under applicable law), and otherwise administer and improve our services. You can set your
          browser not to accept cookies, to remove cookies, or to notify you when you are sent a cookie, giving you the opportunity to decide whether
          or not to accept it. However, please note that if you disable the use of cookies on your computer, some functionality of our Website will
          not work.{' '}
        </span>
      </p>
      <h3 className="c17" id="h.4d34og8-8">
        <span className="c13 c1 c6">10. Accessing and correcting your personal information</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">
          10.1 We will take such steps as are reasonable in the circumstances to ensure that the Personal Information which we collect remains
          accurate, up to date and complete. You can request access to your Personal Information or advise us of any required correction to your
          Personal Information by sending a request by logging in to your account or by contacting our Privacy Officer. Upon request for access to
          Personal Information, we will provide you with details of any of your Personal Information that we hold, subject to appropriate identity
          verification and except in the limited circumstances permitted by law (for example if granting access would interfere with the privacy of
          others or if it would result in a breach of confidentiality). When making an access or correction request, please provide as much detail as
          possible to assist us. &nbsp;There is no charge for requesting access to your Personal Information but we may require you to meet our
          reasonable costs in actually providing you with access.
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">10.2 If you make a request for access or correction of your Personal Information we will: </span>
      </p>
      <ol className="c0 lst-kix_bk1ome99d61y-0 start" start="1">
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">
            take reasonable steps, consistent with our obligations under the Privacy Act, to provide access to that information and consider if the
            Personal Information requires correction; and
          </span>
        </li>
        <li className="c4 c8 li-bullet-1">
          <span className="c5 c1">respond to all requests for access and/or correction within a reasonable time.</span>
        </li>
      </ol>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <h3 className="c17" id="h.2s8eyo1-9">
        <span className="c13 c1 c6">11. Overseas transfers of personal information</span>
      </h3>
      <p className="c4">
        <span className="c5 c1">
          11.1 From time to time we may engage an overseas service provider to provide services to us, such as cloud-based storage solutions. By
          providing us with your personal information, you consent to your personal information being handled and stored overseas, including being
          stored on servers located in the USA, Europe or Asia.{' '}
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c1">
          11.2 You hereby acknowledge and agree that Australian Privacy Principal 8.1 does not apply to such disclosures. For the avoidance of doubt,
          in the event than an overseas service provider breaches the APPs, that entity will not be bound by, and you will have no right to seek
          redress under, the{' '}
        </span>
        <span className="c1 c34">Privacy Act</span>
        <span className="c5 c1">.</span>
      </p>
      <h3 className="c17" id="h.17dp8vu-10">
        <span className="c1 c6 c31">12. Resolving personal information concerns</span>
      </h3>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          12.1 If you have any questions, concerns or complaints about this Privacy Policy, or how we handle your personal information, including if
          you wish to withdraw consent to the collection of your personal information, please direct your enquiries to:
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">The Privacy Officer</span>
      </p>
      <p className="c4">
        <span className="c5 c1">WHEN Fertility Pty Ltd</span>
      </p>
      <p className="c4">
        <span className="c5 c1">165 Riley Street</span>
      </p>
      <p className="c4">
        <span className="c5 c1">Darlinghurst NSW 2010</span>
      </p>
      <p className="c4">
        <span className="c1">Email: </span>
        <span className="c1 c21">
          <a className="c20" href="mailto:hello@whenfertility.com.au">
            hello@whenfertility.com.au
          </a>
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          12.2 Your experience with WHEN Fertility is important to us. We endeavour to respond to any complaint within a reasonable period.
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          12.3 If you are dissatisfied with the handling of your complaint, you may contact the Office of the Australian Information Commissioner:
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">Office of the Australian Information Commissioner</span>
      </p>
      <p className="c4">
        <span className="c5 c1">GPO Box 5218 Sydney NSW 2001</span>
      </p>
      <p className="c4">
        <span className="c5 c1">Telephone: 1300 363 992 </span>
      </p>
      <p className="c4">
        <span className="c1">or via their website </span>
        <span className="c1">
          <a
            className="c20"
            href="https://www.google.com/url?q=https://www.oaic.gov.au/&amp;sa=D&amp;source=editors&amp;ust=1716785023872170&amp;usg=AOvVaw3UIuxmvltY5RTzqgPlMX1l"
          >
            https://www.oaic.gov.au/
          </a>
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <h3 className="c17" id="h.3rdcrjn-11">
        <span className="c13 c1 c6">13. Changes</span>
      </h3>
      <p className="c4 c11">
        <span className="c5 c14"></span>
      </p>
      <p className="c4">
        <span className="c5 c1">
          13.1 With a view to improving our service to you and ensuring compliance with the Australian Privacy Principles, we may revise this Privacy
          Policy from time to time. &nbsp;It is your responsibility to remain aware of the content of the then applicable policy. You may not be
          directly notified of such a change so please ensure that you regularly check this Privacy Policy so you are fully aware of any changes or
          updates.
        </span>
      </p>
      <p className="c4 c11">
        <span className="c5 c1"></span>
      </p>
      <p className="c28">
        <span className="c5 c3">Policy Number: WF005.v3</span>
      </p>
      <div>
        <p className="c11 c16">
          <span className="c5 c14"></span>
        </p>
      </div>
    </div>
  );
};
