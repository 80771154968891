import { COMMON_ROUTES } from '@when-fertility/shared/domain/common';
import { useCurrentUser } from 'domain/user/current-user.hook';
import { GqlUserRole } from '@when-fertility/shared/gql/graphql';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from 'domain/auth';

type Props = {
  role: GqlUserRole;
};

export const RoleLayout = ({ role }: Props) => {
  const { role: currentUserRole, isLoading, error } = useCurrentUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (!currentUserRole || !role || currentUserRole === role) {
      return;
    }
    navigate(COMMON_ROUTES.unauthorised);
  }, [pathname, currentUserRole, role]);

  // @TODO: Add better error page.
  if (error) {
    navigate(AUTH_ROUTES.login);
  }

  if (isLoading || !currentUserRole || !role) {
    return null;
  }

  return <Outlet />;
};
