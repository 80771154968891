import React, { FC } from 'react';
import cn from 'classnames';

interface SectionSeparatorProps {
  title: string;
  number?: string; // Optional in case you have sections without numbers
  seperatorType?: SeperatorType;
  subTitle?: string;
}
export type SeperatorType = 'lemon' | 'transparent';
export const SectionSeparator: FC<SectionSeparatorProps> = ({ title, number = '01', seperatorType = 'lemon', subTitle = '' }) => {
  const seperatorClasses = cn({
    'pt-10 sm:pt-16 sm:pb-16 bg-lemon-60': seperatorType === 'lemon',
    'pt-10 bg-transparent': seperatorType === 'transparent',
  });
  const titleClasses = cn('flex items-center border-t  px-4 lg:px-40', {
    'border-black py-16': seperatorType === 'lemon',
    'border-white py-10': seperatorType === 'transparent',
  });
  const numberClasses = cn('text-left w-20 inline-block px-3 py-1 rounded-full text-2xl mr-3 border yellow-300', {
    'bg-lemon-60  border-black': seperatorType === 'lemon',
    'bg-transparent text-white border-white': seperatorType === 'transparent',
  });

  const textClasses = cn('text-4xl ml-2', {
    'text-white': seperatorType === 'transparent',
  });

  return (
    <div className={seperatorClasses}>
      <div className={titleClasses}>
        <span className={numberClasses}>{number}</span>
        <span className={textClasses}>{title}</span>
      </div>
      {subTitle ? <h2 className="text-white px-4 lg:px-40 mb-3">{subTitle}</h2> : ''}
    </div>
  );
};
