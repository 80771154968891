import { Button, Icon } from '@when-fertility/shared/domain/common';
import React from 'react';
import cn from 'classnames';

type Props = {
  variant: 'add' | 'edit';
  onClick: () => void;
};

export const AddOrEditButton = ({ variant, onClick }: Props) => {
  const iconClasses = cn('mr-2', {
    'text-white stroke-[3px] group-hover:text-charcoal-100': variant === 'add',
    'group-hover:text-white': variant === 'edit',
  });

  return (
    <Button className="flex items-center group" variant={variant === 'add' ? 'primary' : 'secondary'} onClick={onClick}>
      <Icon
        icon={variant === 'add' ? 'plus' : 'pencil'}
        height={variant === 'add' ? 11 : 14}
        width={variant === 'add' ? 11 : 14}
        className={iconClasses}
      />
      {variant === 'add' ? 'Add' : 'Edit'}
    </Button>
  );
};
