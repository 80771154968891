import { Button, Input } from '@when-fertility/shared/domain/common';
import { useActivateActivationCodeCheckLazyQuery } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React, { useEffect } from 'react';
import { captureException } from '@sentry/react';

export const ACTIVATION_CODE_DIGITS = 7;

type Props = {
  activationCode: any;
  setActivationCode: (value: string) => void;
  onSuccess: () => void;
  checkCodeOnLoad: boolean;
  isPrepopulated: boolean;
};

export const ActivateActivationCode = ({ activationCode, setActivationCode, onSuccess, checkCodeOnLoad = false, isPrepopulated = false }: Props) => {
  const [checkActivationCode, { data, loading, error }] = useActivateActivationCodeCheckLazyQuery();

  const checkValidation = async () => {
    if (activationCode.length !== ACTIVATION_CODE_DIGITS) {
      return;
    }
    try {
      await checkActivationCode({
        variables: { input: { activationCode } },
        onCompleted: async (data) => {
          if (data.checkActivationCode.isValid) {
            onSuccess();
          }
        },
      });
    } catch (error: any) {
      captureException(error);
      console.error(error.message);
    }
  };

  useEffect(() => {
    checkCodeOnLoad ? checkValidation() : null;
  }, [activationCode, checkActivationCode, onSuccess]);

  const handleNext = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await checkValidation();
  };

  return (
    <form className="text-center">
      <div className="text-4xl mb-6">Activate your kit</div>
      <div className="mb-6">
        {isPrepopulated && `Confirm the code below matches your kit to successfully link it to your profile`}
        {!isPrepopulated && (
          <>
            Enter your unique {ACTIVATION_CODE_DIGITS}-letter activation code below.
            <br /> You'll find it on the activation card inside your kit.
          </>
        )}
      </div>
      <Input
        value={activationCode}
        maxLength={ACTIVATION_CODE_DIGITS}
        type="text"
        onChange={({ target }) => setActivationCode(target.value)}
        isRequired
        placeholder="Enter code"
        isDisabled={loading}
        isError={Boolean(error)}
      />
      {activationCode.length >= ACTIVATION_CODE_DIGITS && (
        <Button className="w-full mt-3 min-h-medium-button" onClick={handleNext}>
          Next
        </Button>
      )}
      {loading && <div className="mt-6">Checking...</div>}
      {Boolean(data?.checkActivationCode.message) && <div className="mt-6 text-red-600">{data?.checkActivationCode.message}</div>}
      {error && <div className="mt-6 text-red-600">Hmm, something went wrong</div>}
      {(error || Boolean(data?.checkActivationCode.message)) && (
        <div className="mt-6">
          <a className="text-artichoke-80">
            Having trouble?
            <br />
            Contact our support team at{' '}
            <a className="underline" href="mailto:hello@whenfertility.com.au">
              hello@whenfertility.com.au
            </a>{' '}
            so we can help.
          </a>
        </div>
      )}
    </form>
  );
};

ActivateActivationCode.query = gql`
  query ActivateActivationCodeCheck($input: CheckActivationCodeInput!) {
    checkActivationCode(input: $input) {
      isValid
      message
    }
  }
`;
