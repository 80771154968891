import { useUseCurrentUserGetUserQuery } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';

export const useCurrentUser = () => {
  const { data, loading, error } = useUseCurrentUserGetUserQuery({ fetchPolicy: 'cache-and-network' });

  return {
    isLoading: loading,
    error: error,
    role: data?.userProfile.role || null,
    loggedInUserId: data?.userProfile.id,
  };
};

useCurrentUser.fragments = {
  user: gql`
    fragment useCurrentUserUserFragment on User {
      id
      role
    }
  `,
};

useCurrentUser.query = gql`
  ${useCurrentUser.fragments.user}

  query useCurrentUserGetUser {
    userProfile {
      ...useCurrentUserUserFragment
    }
  }
`;
