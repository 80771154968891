import { format } from 'date-fns';
import { useActivateTestKitMutation, useActivatePageGetUserQuery } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Input, Page } from '@when-fertility/shared/domain/common';
import { validateDateOfBirthSchema } from '@when-fertility/shared/domain/common/services/dates.service';
import { URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import { QUIZ_ROUTES } from '../quiz';
import { ActivateActivationCode, ACTIVATION_CODE_DIGITS } from './components/activate-activation-code';
import { captureException } from '@sentry/react';
import { ZodError } from 'zod';

interface DateChangeEvent {
  target: { value: string };
}

export const DateOfBirthPage = () => {
  const [activationCodeValidated, setActivationCodeValidated] = useState(false);
  const [activationCode, setActivationCode] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [isInvalidAge, setIsInvalidAge] = useState(false);
  const [activateTestKit, { loading, error }] = useActivateTestKitMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useActivatePageGetUserQuery({
    onCompleted(data) {
      if (data?.userProfile.dateOfBirth) {
        const date = format(new Date(data?.userProfile.dateOfBirth), 'yyyy-MM-dd');
        setDateOfBirth(date);
      }
    },
  });

  useEffect(() => {
    const urlActivationCode = searchParams.get(URL_PARAMETERS.activationCode);

    // TODO: validation (if any)
    setActivationCode(urlActivationCode?.substring(0, ACTIVATION_CODE_DIGITS) || ''); // Only take first 7 characters

    // TODO: automatically activate?
  }, [searchParams]);

  const handleActivate = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      await activateTestKit({
        variables: { input: { activationCode, dateOfBirth } },
        onCompleted: async () => {
          navigate(QUIZ_ROUTES.root);
        },
      });
    } catch (error: any) {
      captureException(error);
      console.error(error.message);
    }
  };

  const handleDateOfBirthChanged = ({ target }: DateChangeEvent) => {
    setDateOfBirth(target.value);
    // Calculate the age
    try {
      validateDateOfBirthSchema.parse(target.value);
    } catch (error) {
      if (error instanceof ZodError) {
        console.error('Validation error:', error.message);
        setIsInvalidAge(true);
        return;
      }
    }
    setIsInvalidAge(false);
  };

  return (
    <Page isCentred className="bg-blob bg-center bg-no-repeat bg-[length:130%] md:bg-[length:auto]" variant="medium">
      <div className="p-10 md:xp-0">
        {!activationCodeValidated && (
          <ActivateActivationCode
            activationCode={activationCode}
            setActivationCode={setActivationCode}
            isPrepopulated={true}
            onSuccess={() => setActivationCodeValidated(true)}
            checkCodeOnLoad={true}
          />
        )}
        {activationCodeValidated && (
          <form className="text-center" onSubmit={handleActivate}>
            <div className="text-4xl mb-3">Activate your kit</div>
            <div className="mb-3">Nice! Before we can link up your kit, what&apos;s your date of birth?</div>
            <Input
              className="min-w-full"
              value={dateOfBirth}
              type="date"
              onChange={handleDateOfBirthChanged}
              isRequired
              isDisabled={loading}
              isError={isInvalidAge}
            />
            {isInvalidAge && <div className="mt-6 text-red-600">This test is for people with ovaries over the age of 25</div>}
            <Button className="w-full mt-3 min-h-medium-button" type="submit" isDisabled={loading || isInvalidAge}>
              Submit
            </Button>
            {error && <div className="mt-6 text-red-600">{error.message}</div>}
          </form>
        )}
      </div>
    </Page>
  );
};

DateOfBirthPage.fragments = {
  user: gql`
    fragment ActivatePageUserFragment on User {
      id
      dateOfBirth
    }
  `,
};

DateOfBirthPage.mutation = gql`
  mutation ActivateTestKit($input: ActivateTestKitInput!) {
    activateTestKit(input: $input) {
      id
    }
  }
`;

DateOfBirthPage.query = gql`
  ${DateOfBirthPage.fragments.user}

  query ActivatePageGetUser {
    userProfile {
      ...ActivatePageUserFragment
    }
  }
`;
