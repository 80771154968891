import React, { useMemo, useState } from 'react';
import { Icon, HeaderNavLink } from '@when-fertility/shared/domain/common';
import { DASHBOARD_ROUTES } from 'domain/dashboard';
import { RESULT_ROUTES } from 'domain/results';
import { ACTIVATE_ROUTES } from 'domain/activate';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { USER_ROUTES } from 'domain/user/user.routes';
import cn from 'classnames';
import { QUIZ_ROUTES } from 'domain/quiz';

// TODO: consider using a dictionary if more logic is added to nav background
const SOLID_BACKGROUND_ROUTES = [USER_ROUTES.profile, USER_ROUTES.activate, USER_ROUTES.dob, RESULT_ROUTES.root, RESULT_ROUTES.report];
const BRIGHT_BACKGROUND_ROUTES = [QUIZ_ROUTES.complete, QUIZ_ROUTES.root];

function matchAnyPath(paths: Array<string>, pathname: string): boolean {
  return paths.some((path) => matchPath(path, pathname));
}

export const Nav = () => {
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const background = useMemo(() => {
    if (matchAnyPath(SOLID_BACKGROUND_ROUTES, pathname)) {
      return 'solid';
    }
    if (matchAnyPath(BRIGHT_BACKGROUND_ROUTES, pathname)) {
      return 'bright';
    }
    return 'transparent';
  }, [pathname]);

  return (
    <div className={cn('absolute sticky top-0 z-50')}>
      <div
        className={cn('flex justify-between w-full items-center px-4 h-nav-mobile md:h-nav-desktop border-b', {
          'bg-silver-40 border-b-charcoal-60': background === 'solid',
          'bg-transparent border-b-white': background === 'transparent',
          'bg-lemon-100 border-b-charcoal-60': background === 'bright',
        })}
      >
        <div className="flex items-center">
          <Link to={DASHBOARD_ROUTES.root} onClick={() => setIsMenuOpen(false)}>
            <div
              className={cn('hidden md:block', {
                'text-white': background === 'transparent',
              })}
            >
              <Icon icon={'when'} height={30} width={100} />
            </div>
            <div
              className={cn('block text-3xl md:hidden', {
                'text-white': background === 'transparent',
              })}
            >
              {/*<Icon icon={'when'} height={30} width={100} />*/}
              WHEN
            </div>
          </Link>
          <div onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Icon
              icon={isMenuOpen ? 'cross' : 'hamburger'}
              height={25}
              width={25}
              className={cn('sm:hidden ml-2', {
                'text-white': background === 'transparent',
              })}
            />
          </div>
        </div>
        <div className="flex">
          <div
            className={cn('hidden sm:flex items-center rounded-full px-6 leading-5 mr-3', {
              'text-white': background === 'transparent',
            })}
          >
            <HeaderNavLink size={'base'} to={DASHBOARD_ROUTES.root}>
              Members Portal
            </HeaderNavLink>
            <HeaderNavLink size={'base'} to={RESULT_ROUTES.root}>
              Reports
            </HeaderNavLink>
            <a className="sm:ml-6 text-base" href="https://www.whenfertility.com.au/buy" target="_self" rel="when fertility shop">
              Buy
            </a>
          </div>
          <div
            className={cn('flex items-center justify-center rounded-full mr-3', {
              'bg-lemon-100 border border-lemon-100 hover:border hover:border-charcoal-100 hover:bg-transparent':
                background === 'solid' || background === 'transparent',
              'bg-lemon-100 border border-charcoal-100': background === 'bright',
            })}
          >
            <HeaderNavLink size={'small'} to={ACTIVATE_ROUTES.root} onClick={() => setIsMenuOpen(false)} className="px-6">
              Activate kit
            </HeaderNavLink>
          </div>
          <div
            className={cn('flex items-center justify-center rounded-full', {
              'bg-silver-100 border border-charcoal-100': background === 'solid',
              'border border-white text-white': background === 'transparent',
              'bg-lemon-100 border border-charcoal-100': background === 'bright',
            })}
          >
            <Link to={USER_ROUTES.profile} onClick={() => setIsMenuOpen(false)} className="flex justify-center items-center h-8 w-8 sm:h-10 sm:w-10">
              <Icon icon="user" />
            </Link>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className={cn('sm:hidden absolute w-full bg-silver-100 flex flex-col p-6 gap-2 drop-shadow-md')} onClick={() => setIsMenuOpen(false)}>
          <HeaderNavLink size="large" to={DASHBOARD_ROUTES.root}>
            Members Portal
          </HeaderNavLink>
          <HeaderNavLink size="large" to={RESULT_ROUTES.root}>
            Reports
          </HeaderNavLink>
          <a className="text-lg" href="https://www.whenfertility.com.au/buy" target="_self" rel="when fertility shop">
            Buy
          </a>
        </div>
      )}
    </div>
  );
};
