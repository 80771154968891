import React, { FC, ReactNode } from 'react';

interface ContentCardProps {
  title: string | ReactNode;
  children: ReactNode; // This will allow you to pass any React elements as children
}

export const ContentCard: FC<ContentCardProps> = ({ title, children }) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-start p-4">
      <div className="text-3xl sm:w-2/6 pb-5">{title}</div>
      <div className="sm:w-4/6">{children}</div>
    </div>
  );
};
