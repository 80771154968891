import React from 'react';
import { Icon } from '@when-fertility/shared/domain/common';
import { DASHBOARD_ROUTES } from 'domain/dashboard';
import { Link } from 'react-router-dom';
import { useUserProfile } from '..';

type Props = {
  setIsModalOpen: (value: boolean) => void;
};

export const QuizHeader = ({ setIsModalOpen }: Props) => {
  const { next, prev, isFirstQuestion, isLastQuestion, quizProgressPercentage } = useUserProfile();

  return (
    <>
      <div className="bg-silver-60 px-4 py-3 sm:px-3 sm:py-2 flex justify-between items-center">
        <div>
          <Link to={DASHBOARD_ROUTES.root} onClick={() => setIsModalOpen(false)}>
            <Icon className="" icon={'when'} height={28} width={100} />
          </Link>
        </div>
        <div className="flex">
          {!isFirstQuestion && (
            <div
              className="rounded-full border border-charcoal-40 mr-1 p-2 h-8 w-8 flex items-center justify-center"
              data-testid="quiz-back"
              onClick={prev}
            >
              <Icon icon="arrow-left" height={9} width={12} className="stroke-[3px]" />
            </div>
          )}
          {!isLastQuestion && (
            <div className="rounded-full border border-charcoal-40 p-2 h-8 w-8 flex items-center justify-center" onClick={next}>
              <Icon icon="arrow-right" height={9} width={12} className="stroke-[3px]" />
            </div>
          )}
        </div>
      </div>
      <div className="bg-artichoke-60 w-full h-0.5">
        {/* tailwindcss 'w-[value%]' doesn't work with dynamic values so need to directly update style prop here */}
        <div style={{ width: `${quizProgressPercentage}%` }} className="bg-artichoke-100 h-0.5"></div>
      </div>
    </>
  );
};
