import React from 'react';
import { BlobType, BlobTypeMap } from './blob-icon.component';
import { Icon } from './icon.component';

type Props = {
  blobType?: BlobType;
  variant?: 'outline' | 'filled';
  text: string;
  blobWidth?: number;
  blobHeight?: number;
};

export const BlobText = ({ blobType = '1', variant = 'outline', text, blobWidth, blobHeight }: Props) => {
  return (
    <div className="flex items-center justify-center">
      <Icon
        width={blobWidth}
        height={blobHeight}
        icon={BlobTypeMap[blobType]}
        className={variant === 'filled' ? 'text-lemon-100' : 'text-artichoke-100'}
        overrideVariant={variant === 'filled' ? 'filled' : 'stroke'}
      ></Icon>
      <span className="absolute font-bold text-lg">{text}</span>
    </div>
  );
};
