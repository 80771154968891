import { initializeApp, getApp, getApps } from 'firebase/app';

const FIREBASE_CONFIG = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID.toString(),
  appId: process.env.FIREBASE_APP_ID,
};

export const firebaseService = getApps().length ? getApp() : initializeApp(FIREBASE_CONFIG);
