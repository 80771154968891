import { Button, Page } from '@when-fertility/shared/domain/common';
import { DASHBOARD_ROUTES } from 'domain/dashboard';
import React from 'react';

export const QuizCompletePage = () => {
  return (
    <Page isCentred variant="bright">
      <div className="pt-24 flex items-center flex-col text-center text-charcoal-100 h-auto md:h-1/2 w-full">
        <div className="text-2xl mt-10">Great! Thanks for activating your kit.</div>
        <div className="mt-7">Now we’ll take you to the members portal and run through the next steps.</div>
        <div className="fixed sm:relative bottom-0 left-0 sm:bottom-auto sm:left-auto flex p-6 w-full sm:w-80 sm:mt-12">
          <Button isLink href={DASHBOARD_ROUTES.root} className="w-full">
            Let’s go
          </Button>
        </div>
      </div>
    </Page>
  );
};
