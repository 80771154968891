import React from 'react';
import { Page } from '@when-fertility/shared/domain/common';
import { Link } from 'react-router-dom';

export const UnauthorisedPage = () => {
  return (
    <Page isCentred>
      <div className="text-2xl">Whoops!</div>
      <div className="mt-2">Looks like you&apos;re not allowed to see this page.</div>
      <Link to="/logout" className="pl-1 underline">
        Try again...
      </Link>
    </Page>
  );
};
