import React from 'react';
import { Icon, IconType } from './icon.component';

export type BlobType = '1' | '2' | '3' | '4' | '5' | '6' | '7';

type Props = {
  blobType?: BlobType;
  variant?: 'outline' | 'filled';
  icon: IconType | null;
  blobWidth?: number;
  blobHeight?: number;
  iconWidth?: number;
  iconHeight?: number;
};

export const BlobTypeMap: Record<BlobType, IconType> = {
  1: 'blob1',
  2: 'blob2',
  3: 'blob3',
  4: 'blob4',
  5: 'blob5',
  6: 'blob6',
  7: 'blob7',
};

export const BlobIcon = ({ blobType = '1', variant = 'outline', icon, iconWidth, iconHeight, blobWidth, blobHeight }: Props) => {
  return (
    <div className="flex items-center justify-center">
      <Icon
        width={blobWidth}
        height={blobHeight}
        icon={BlobTypeMap[blobType]}
        className={variant === 'filled' ? 'text-lemon-100' : 'text-artichoke-100'}
        overrideVariant={variant === 'filled' ? 'filled' : 'stroke'}
      ></Icon>
      {icon && <Icon width={iconWidth} height={iconHeight} icon={icon} className="absolute"></Icon>}
    </div>
  );
};
