import React, { memo, ReactNode } from 'react';
import cn from 'classnames';

type Props = {
  title?: string;
  headerClassName?: string;
  className?: string;
  children?: ReactNode;
  variant?: 'medium' | 'light' | 'bright';
  isCentred?: boolean;
  titleVariant?: 'light' | 'bright';
  hasPadding?: boolean;
  hasMaxWidth?: boolean;
};

export const Page = memo(
  ({
    title,
    headerClassName,
    className,
    children,
    variant = 'light',
    isCentred = false,
    titleVariant = 'bright',
    hasPadding = true,
    hasMaxWidth = true,
  }: Props) => {
    const classes = cn(className, 'h-full min-h-screen -mt-page-mobile md:-mt-page-desktop', {
      'bg-silver-100': variant === 'medium',
      'bg-silver-40': variant === 'light',
      'bg-lemon-100': variant === 'bright',
      'flex justify-center items-center flex-col': isCentred,
      'pb-12': hasPadding,
    });

    const headerClasses = cn(headerClassName, 'px-6 lg:px-24 flex justify-center');

    const titleClasses = cn('text-3xl md:text-5xl pt-24 md:pt-40 max-w-7xl w-full', {
      'text-charcoal': titleVariant === 'bright',
      'text-silver-60': titleVariant === 'light',
    });

    const contentClasses = cn('flex justify-center', {
      'mt-8 px-6 lg:px-24': hasPadding,
    });
    const contentWidthClasses = cn(' w-full', {
      'max-w-7xl': hasMaxWidth,
    });

    return (
      <>
        <div className={classes}>
          <div className={headerClasses}>{Boolean(title) && <div className={titleClasses}>{title}</div>}</div>
          <div className={contentClasses}>
            <div className={contentWidthClasses}>{children}</div>
          </div>
        </div>
      </>
    );
  }
);
