import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { TermsAndConditionsContentComponent } from './terms-and-conditions-content.component';

function TermsAndConditionsComponent({
  setHasRead,
  children,
}: {
  setHasRead: Dispatch<SetStateAction<boolean>>;
  children?: React.ReactNode; // Include children prop in the prop type definition
}) {
  //Set initial value to be 500, so initially its bigger content height for setHasRead being false
  const [childHeight, setChildHeight] = useState(500);
  const childRef = useRef(null);

  const updateHeight = () => {
    if (childRef.current) {
      const height = childRef.current.getBoundingClientRect().height;
      setChildHeight(height);
    }
  };

  function handleScroll(event: any) {
    updateHeight();
    const { scrollTop } = event.target;
    // 460 is w-150 content height
    if (scrollTop >= childHeight - 460) {
      setHasRead(true);
    }
  }
  return (
    <div className="md:p-4">
      <p className="mb-4">Please read and scroll to the bottom of our Terms and Conditions and Privacy Policy and accept below:</p>
      <div id="terms-conditions-container" className="overflow-auto h-100" onScroll={handleScroll}>
        <div id="content" ref={childRef} className="px-2 md:px-10 py-10 md:w-150 bg-white">
          {/*terms and condition content*/}
          <TermsAndConditionsContentComponent />
        </div>
      </div>
      {children}
    </div>
  );
}

export default TermsAndConditionsComponent;
