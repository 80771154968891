import React, { useMemo, useState } from 'react';
import { ProfileQuestion } from '@when-fertility/shared/user-profile';
import { useUserProfile } from '../patient-profile.provider';
import { AddOrEditButton } from './add-or-edit-button.component';
import { EditProfileField } from './edit-profile-field.component';
import cn from 'classnames';
import { FullScreenModalComponent } from '@when-fertility/shared/domain/common/components';

export const ProfileSummary = () => {
  const { profileQuestions, resetFormData, setCurrentQuestion, prettyPrintAnswer, canShowQuestion, formData } = useUserProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClickEdit = (question: ProfileQuestion) => {
    setCurrentQuestion(question);
    setIsModalOpen(true);
  };

  const unansweredRequiredQuestions = useMemo(
    () => (Object.values(formData).length && profileQuestions?.filter((q) => Boolean(q.isRequiredToCompleteProfile && !formData[q.key]))) || [],
    [formData, profileQuestions]
  );

  const onCloseModal = (isOpen: boolean) => {
    resetFormData();
    setIsModalOpen(isOpen);
  };

  return (
    <div>
      <div className="text-2xl mb-4">Profile</div>
      <div className="mb-4 text-charcoal-80">Keep your profile up to date to make sure you&apos;re getting the most personalised experience.</div>
      {Boolean(unansweredRequiredQuestions.length) && (
        <div className="bg-lemon-100 p-3 rounded-md text-center border border-silver-100 mb-4">
          You have <span className="font-semibold">{unansweredRequiredQuestions.length}</span> profile details remaining to be completed
        </div>
      )}
      <div className="rounded-xl border border-silver-100">
        {profileQuestions
          .filter((q) => q.type !== 'info')
          .map((question, count) => {
            if (!canShowQuestion({ question })) {
              return null;
            }
            return (
              <React.Fragment key={count}>
                {/* Mobile view */}
                <div
                  className={cn('md:hidden flex justify-between items-center border-t border-silver-100 first:border-none p-4', {
                    'bg-silver-20': question.isRequiredToCompleteProfile && !prettyPrintAnswer(question),
                  })}
                >
                  <div className="w-1/2">
                    <div className="text-charcoal-60">{question.label}</div>
                    <div className="mt-1">{prettyPrintAnswer(question)}</div>
                  </div>
                  <div>
                    <AddOrEditButton onClick={() => handleClickEdit(question)} variant={prettyPrintAnswer(question) ? 'edit' : 'add'} />
                  </div>
                </div>
                {/* Desktop view */}
                <div
                  className={cn('hidden md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none', {
                    'bg-silver-20': question.isRequiredToCompleteProfile && !prettyPrintAnswer(question),
                  })}
                >
                  <div className="w-1/4 text-charcoal-60">{question.label}</div>
                  <div className="w-3/4 flex justify-between items-center ml-12">
                    <div className="flex-1 whitespace-pre-wrap">{prettyPrintAnswer(question)}</div>
                    <AddOrEditButton onClick={() => handleClickEdit(question)} variant={prettyPrintAnswer(question) ? 'edit' : 'add'} />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
      </div>
      <FullScreenModalComponent isOpen={isModalOpen} setIsOpen={onCloseModal}>
        <EditProfileField onFinished={() => setIsModalOpen(false)} />
      </FullScreenModalComponent>
    </div>
  );
};
