import { useUserProfile } from 'domain/user';
import React from 'react';
import { QuizSingleChoice, QuizMultiSelect, QuizTextArea, QuizInput } from '..';
type Props = {
  setShowNextButton: () => void;
};

export const QuizQuestion = ({ setShowNextButton }: Props) => {
  const { currentQuestion, errorMessage } = useUserProfile();
  return (
    <>
      {currentQuestion.type === 'info' && (
        <div className="flex items-center justify-center mt-16">
          <div className="h-96 flex flex-col justify-center items-center bg-blob-small bg-center bg-contain bg-no-repeat p-12 text-center">
            <div className="text-xl mb-4 text-center">{currentQuestion.title}</div>
            {currentQuestion.blurb && <div className="text-center">{currentQuestion.blurb}</div>}
          </div>
        </div>
      )}
      {currentQuestion.type !== 'info' && (
        <div className="w-full">
          <div className="text-xl mb-4">{currentQuestion.title}</div>
          {currentQuestion.blurb && <div className="mb-6">{currentQuestion.blurb}</div>}
          {errorMessage && <div className="text-sm text-red-600 mt-6">{errorMessage}</div>}
          <div className="h-full flex flex-col justify-between">
            <div>
              {currentQuestion.type === 'select' && (
                <QuizSingleChoice setShowNextButton={setShowNextButton} key={currentQuestion.label} options={currentQuestion.options} />
              )}
              {currentQuestion.type === 'multi-select' && <QuizMultiSelect options={currentQuestion.options} />}
              {currentQuestion.type === 'text' && <QuizInput />}
              {currentQuestion.type === 'textarea' && <QuizTextArea />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
