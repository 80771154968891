import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { useSettingsSummaryGetUserQuery } from '@when-fertility/shared/gql/graphql';
import { SettingsSummaryItem } from './setting-summary-item.component';
import { useAuth } from '../../auth';
import { SettingsSummaryAddressItem } from './setting-summary-item-address.component';

export const SettingsSummary = () => {
  const { data } = useSettingsSummaryGetUserQuery();
  const { logout } = useAuth();

  const { firstName, lastName, email, phone, address } = useMemo(() => {
    return {
      firstName: data?.userProfile.firstName,
      lastName: data?.userProfile.lastName,
      email: data?.userProfile.email,
      phone: data?.userProfile.phone,
      address: data?.userProfile.address,
    };
  }, [data]);

  return (
    <div>
      <div className="text-2xl mb-4">My Details</div>
      <div className="rounded-xl border border-silver-100">
        <SettingsSummaryItem settingsItem="firstName" defaultValue={firstName} />
        <SettingsSummaryItem settingsItem="lastName" defaultValue={lastName} />
        <SettingsSummaryItem settingsItem="email" defaultValue={email} showDisclaimer={true} onSuccess={() => logout()} />
        <SettingsSummaryItem settingsItem="password" defaultValue="●●●●●●●●" showDisclaimer={true} onSuccess={() => logout()} />
        <SettingsSummaryItem settingsItem="phone" defaultValue={phone} />
        <SettingsSummaryAddressItem settingsItem="address" defaultValue={address} />
      </div>
    </div>
  );
};

SettingsSummary.fragments = {
  user: gql`
    fragment SettingsSummaryUserFragment on User {
      id
      firstName
      lastName
      email
      phone
      address {
        address1
        address2
        city
        province
        country
        zip
        phone
        province_code
        country_code
        country_name
      }
    }
  `,
};

SettingsSummary.query = gql`
  ${SettingsSummary.fragments.user}
  query SettingsSummaryGetUser {
    userProfile {
      ...SettingsSummaryUserFragment
    }
  }
`;
