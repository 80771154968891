import React, { useMemo, useState } from 'react';
import { Quiz } from './components/quiz.component';
import { PatientProfileProvider } from '../user/patient-profile.provider';
import { Button, Icon, Page } from '@when-fertility/shared/domain/common';
import { QuizHeader } from './components/quiz-header.component';
import { QUIZ_ROUTES } from './quiz.routes';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTES } from '../dashboard';
import gql from 'graphql-tag';
import { testKitService } from '../test-kit';
import { GqlQuizPageTestKitFragmentFragment, useQuizPageGetTestKitsQuery } from '@when-fertility/shared/gql/graphql';
import { FullScreenModalComponent } from '@when-fertility/shared/domain/common/components';
export const QuizPage = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const navigate = useNavigate();
  const { data } = useQuizPageGetTestKitsQuery({ fetchPolicy: 'cache-and-network' });

  const latestTestKit = useMemo(() => testKitService.getLatestTestKit<GqlQuizPageTestKitFragmentFragment>(data?.testKits.testKits), [data]);
  const isTestKitActivated = useMemo(() => testKitService.getIsTestKitActivated(latestTestKit?.status), [latestTestKit?.status]);

  // TODO: any dynamic loading of which kit we are loading, or handling the quiz for multiple kits?
  return (
    <>
      <Page isCentred variant="bright">
        <div className="w-full md:w-96 p-6">
          <div className="text-center">
            {isTestKitActivated && (
              <>
                <div className="text-2xl">Your kit has been activated!</div>
                <div className="text-md font-bold">Now, let&apos;s complete your profile.</div>
              </>
            )}
            {!isTestKitActivated && (
              <>
                <div className="text-2xl">Complete your profile</div>
              </>
            )}
            <div className="mt-6">
              We're about to ask you some questions to help us understand your overall fertility picture a little better. Test results are really
              helpful but need to be considered with other factors too.
            </div>
            <div className="mt-4">It's all straightforward and should only take about 2 minutes.</div>
            <div className="mt-4">Ready when you are!</div>
          </div>
          <div className="absolute bottom-12 p-6 sm:p-0 left-0 md:left-auto flex flex-col w-full md:w-96">
            <div className="text-center text-sm mb-4 text-charcoal-60">
              <Icon height={19} icon="clock" /> Takes 2 minutes
            </div>
            <Button onClick={() => setIsOverlayOpen(true)}>Let&apos;s Go</Button>
            <Button variant="secondary" className="mt-2" onClick={() => navigate(DASHBOARD_ROUTES.root)}>
              I&apos;ll do this later
            </Button>
          </div>
        </div>
        <PatientProfileProvider
          saveOnFinish
          onFinish={() => {
            navigate(QUIZ_ROUTES.complete);
          }}
        >
          <FullScreenModalComponent
            isOpen={isOverlayOpen}
            setIsOpen={setIsOverlayOpen}
            customHeader={<QuizHeader setIsModalOpen={setIsOverlayOpen} />}
          >
            <Quiz />
          </FullScreenModalComponent>
        </PatientProfileProvider>
      </Page>
    </>
  );
};

QuizPage.fragments = {
  testKits: gql`
    fragment QuizPageTestKitFragment on TestKit {
      id
      status
    }
  `,
};

QuizPage.query = gql`
  ${QuizPage.fragments.testKits}

  query QuizPageGetTestKits($input: TestKitInput) {
    testKits(input: $input) {
      testKits {
        ...QuizPageTestKitFragment
      }
    }
  }
`;
