import React from 'react';
import { GqlResultReferenceRange } from '@when-fertility/shared/gql/graphql';
interface HearFromAdvisorsProps {
  referenceRange: GqlResultReferenceRange | undefined;
}

const MATSERCLASS_VIDEO_MAP: Record<GqlResultReferenceRange, string> = {
  PERCENTILE_2_5: '954643912?h=f60c638b4b',
  LOW: '954643912?h=f60c638b4b',
  IN_RANGE: '954643774?h=206f230862',
  HIGH: '954655551?h=0d4b4e7c78',
};
export const MasterclassVideo = ({ referenceRange = 'PERCENTILE_2_5' }: HearFromAdvisorsProps) => {
  const masterclassVideoId: string = MATSERCLASS_VIDEO_MAP[referenceRange];
  return (
    <div id={'masterclass_video_id'}>
      <div className={'flex mt-8 lg:ml-24 lg:mt-0'}>
        <div className={'w-56 lg:w-72'}>
          <div className="pt-[60%] relative">
            <iframe
              src={`https://player.vimeo.com/video/${masterclassVideoId}&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
              className="absolute top-0 left-0 w-full h-full"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
