import React, { memo, useEffect } from 'react';
import { useAuth } from '../auth.provider';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '../auth.routes';

export const LogoutPage = memo(() => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await logout();

      navigate(AUTH_ROUTES.login);
    })();
  }, []);

  return (
    <div className="h-full min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">Logging you out...</h2>
        </div>
      </div>
    </div>
  );
});
