import React, { FormEvent, useEffect, useState } from 'react';
import { authService, AUTH_ROUTES, useAuth } from '..';
import { Link } from 'react-router-dom';
import { Button, Input, Page } from '@when-fertility/shared/domain/common';
import { captureException } from '@sentry/react';

export const ForgotPasswordPage = () => {
  const { logout } = useAuth();
  const [formData, setFormData] = useState({ email: '' });
  const [isSaving, setIsSaving] = useState(false);
  const [formError, setFormError] = useState('');
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    (async () => {
      // Logging the user out of any sessions they may be in if they come directly to the forgot-password page.
      await logout();
    })();
  }, []);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setFormError('');
      setIsSaving(true);
      await authService.sendResetPasswordEmail(formData.email);
      setIsSent(true);
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        setFormError('Cannot find user with that email');
      } else {
        captureException(error);
        setFormError('unexpected error occurred');
      }
      setIsSaving(false);
    }
  };

  return (
    <Page isCentred variant="medium" className="bg-blob-outline bg-center bg-no-repeat bg-[length:130%] md:bg-[length:auto]">
      <div className="w-full sm:w-80">
        <div>
          <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">Forgot Password</h2>
        </div>
        {isSent ? (
          <div>Great! We&apos;ve sent you an email to reset your password.</div>
        ) : (
          <form className="mt-8" onSubmit={handleFormSubmit}>
            <div className="mb-3">
              <Input
                type="email"
                onChange={({ target }) => setFormData({ ...formData, email: target.value })}
                isRequired
                isDisabled={isSaving}
                placeholder="Your email address"
              />
            </div>
            <Button
              type="submit"
              isDisabled={isSaving}
              className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-charcoal-100"
            >
              Reset password
            </Button>
            {formError && <div className="text-red-600 text-center text-sm mb-4 mt-2">{formError}</div>}
          </form>
        )}
        <div className="flex flex-col items-center mt-6 text-xs">
          <div className="mb-2 text-charcoal-100">
            Already a member?
            <Link to="/login" className="pl-1 underline">
              Log in
            </Link>
          </div>
          <div className="mb-2 text-xs text-charcoal-100">
            Don&apos;t have an account?
            <Link to={AUTH_ROUTES.register} className="pl-1 underline">
              Sign up here
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
};
