import React, { useState } from 'react';

export const Tooltip = ({ text, children }: { text: string; children: any }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        className={`bg-artichoke-100 w-64 text-white text-center py-4 mb-5 px-4 rounded-xl absolute bottom-full left-1/2 transform -translate-x-1/2 opacity-0 pointer-events-none transition-opacity duration-300 ${
          isHovered ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {text}
        <div
          className={`absolute top-full left-1/2 transform -translate-x-1/2 w-0 h-1  mt-0 border-l-8 border-r-8 border-t-8 border-b-0 border-transparent border-t-artichoke-100 ${
            isHovered ? 'opacity-100' : 'opacity-0'
          }`}
        ></div>
      </div>
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {children}
      </div>
    </div>
  );
};
