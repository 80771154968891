import React from 'react';
import { Page } from '../page/page.component';

type Props = {
  isPage?: boolean;
};

export const ErrorComponent = ({ isPage = false }: Props) => {
  return (
    <>
      {isPage && (
        <Page isCentred>
          <div className="text-2xl">Whoops!</div>
          <div className="mt-2">Something went wrong there. Please try again</div>
        </Page>
      )}
      {!isPage && (
        <>
          <div className="text-2xl">Whoops!</div>
          <div className="mt-2">Something went wrong there. Please try again</div>
        </>
      )}
    </>
  );
};
