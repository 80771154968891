import React, { memo } from 'react';
import cn from 'classnames';

import UserSvg from '@when-fertility/shared/assets/svg/icon-user.svg';
import ArrowRightSvg from '@when-fertility/shared/assets/svg/icon-arrow-right.svg';
import ArrowLeftSvg from '@when-fertility/shared/assets/svg/icon-arrow-left.svg';
import ResultSvg from '@when-fertility/shared/assets/svg/icon-result.svg';
import RefreshSvg from '@when-fertility/shared/assets/svg/icon-refresh.svg';
import NewsSvg from '@when-fertility/shared/assets/svg/icon-news.svg';
import PlusSvg from '@when-fertility/shared/assets/svg/icon-plus.svg';
import MinusSvg from '@when-fertility/shared/assets/svg/icon-minus.svg';
import CheckSvg from '@when-fertility/shared/assets/svg/icon-check.svg';
import CheckRound from '@when-fertility/shared/assets/svg/icon-check-round.svg';
import PencilSvg from '@when-fertility/shared/assets/svg/icon-pencil.svg';
import CrossSvg from '@when-fertility/shared/assets/svg/icon-cross.svg';
import HamburgerSvg from '@when-fertility/shared/assets/svg/icon-hamburger.svg';
import WhenSvg from '@when-fertility/shared/assets/svg/icon-when.svg';
import WhenOutlineSvg from '@when-fertility/shared/assets/svg/icon-when-outline.svg';
import CalendarSvg from '@when-fertility/shared/assets/svg/icon-calendar.svg';
import MailSvg from '@when-fertility/shared/assets/svg/icon-mail.svg';
import Blob1Svg from '@when-fertility/shared/assets/svg/icon-blob-1.svg';
import Blob2Svg from '@when-fertility/shared/assets/svg/icon-blob-2.svg';
import Blob3Svg from '@when-fertility/shared/assets/svg/icon-blob-3.svg';
import Blob4Svg from '@when-fertility/shared/assets/svg/icon-blob-4.svg';
import Blob5Svg from '@when-fertility/shared/assets/svg/icon-blob-5.svg';
import Blob6Svg from '@when-fertility/shared/assets/svg/icon-blob-6.svg';
import Blob7Svg from '@when-fertility/shared/assets/svg/icon-blob-7.svg';
import InfoSvg from '@when-fertility/shared/assets/svg/icon-info.svg';
import CogSvg from '@when-fertility/shared/assets/svg/icon-cog.svg';
import CartSvg from '@when-fertility/shared/assets/svg/icon-cart.svg';
import ClockSvg from '@when-fertility/shared/assets/svg/icon-clock.svg';
import EyeOpenSvg from '@when-fertility/shared/assets/svg/icon-eye-open.svg';
import NextToTalk from '@when-fertility/shared/assets/svg/image-next-to-talk.svg';
import MinusRoundSvg from '@when-fertility/shared/assets/svg/icon-minus-round.svg';
import RightArrowRoundSvg from '@when-fertility/shared/assets/svg/icon-right-arrow-round.svg';
import DownloadSvg from '@when-fertility/shared/assets/svg/icon-download.svg';

export type IconType =
  | 'user'
  | 'arrow-right'
  | 'arrow-left'
  | 'result'
  | 'refresh'
  | 'check'
  | 'check-round'
  | 'news'
  | 'plus'
  | 'minus'
  | 'pencil'
  | 'cross'
  | 'hamburger'
  | 'when'
  | 'when-outline'
  | 'calender'
  | 'mail'
  | 'blob1'
  | 'blob2'
  | 'blob3'
  | 'blob4'
  | 'blob5'
  | 'blob6'
  | 'blob7'
  | 'info'
  | 'cog'
  | 'cart'
  | 'clock'
  | 'eye-open'
  | 'next-to-talk'
  | 'minus-round'
  | 'right-arrow-round'
  | 'download';

type Props = {
  icon: IconType;
  className?: string;
  height?: number;
  width?: number;
  children?: React.ReactNode;
  overrideVariant?: 'stroke' | 'filled';
};

const BASE_CLASSES = ['inline-block'];

export const Icon = memo(({ className, icon, height, width, overrideVariant, ...rest }: Props) => {
  const fillIconClasses = cn(className, BASE_CLASSES, 'fill-current');
  const strokeIconClasses = cn(className, BASE_CLASSES, 'stroke-current');

  const overrideVariantClasses = overrideVariant ? (overrideVariant === 'filled' ? fillIconClasses : strokeIconClasses) : '';

  switch (icon) {
    case 'user':
      return <UserSvg {...rest} width={width || 12} height={height || 14} className={overrideVariantClasses || strokeIconClasses} />;
    case 'arrow-right':
      return <ArrowRightSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 18} height={height || 16} />;
    case 'arrow-left':
      return <ArrowLeftSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 18} height={height || 16} />;
    case 'result':
      return <ResultSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 17} height={height || 17} />;
    case 'refresh':
      return (
        <RefreshSvg
          {...rest}
          className={`text-artichoke-100 ${overrideVariantClasses || strokeIconClasses}`}
          width={width || 14}
          height={height || 14}
        />
      );
    case 'check':
      return <CheckSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 12} height={height || 9} />;
    case 'check-round':
      return <CheckRound className={overrideVariantClasses || strokeIconClasses} width={width || 12} height={height || 9} />;
    case 'news':
      return <NewsSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 18} height={height || 16} />;
    case 'plus':
      return <PlusSvg {...rest} className={overrideVariantClasses || fillIconClasses} width={width || 29} height={height || 29} />;
    case 'minus':
      return <MinusSvg {...rest} className={overrideVariantClasses || fillIconClasses} width={width || 27} />;
    case 'pencil':
      return <PencilSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 17} height={height || 18} />;
    case 'cross':
      return <CrossSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 22} height={height || 21} />;
    case 'hamburger':
      return <HamburgerSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 20} height={height || 13} />;
    case 'when':
      return <WhenSvg {...rest} className={overrideVariantClasses || fillIconClasses} width={width || 41} height={height || 41} />;
    case 'when-outline':
      return <WhenOutlineSvg {...rest} className={overrideVariantClasses || fillIconClasses} width={width || 41} height={height || 41} />;
    case 'calender':
      return <CalendarSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 18} height={height || 19} />;
    case 'mail':
      return <MailSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 12} height={height || 10} />;
    case 'blob1':
      return <Blob1Svg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 42} height={height || 43} />;
    case 'blob2':
      return <Blob2Svg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 42} height={height || 43} />;
    case 'blob3':
      return <Blob3Svg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 42} height={height || 43} />;
    case 'blob4':
      return <Blob4Svg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 42} height={height || 43} />;
    case 'blob5':
      return <Blob5Svg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 42} height={height || 43} />;
    case 'blob6':
      return <Blob6Svg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 42} height={height || 43} />;
    case 'blob7':
      return <Blob7Svg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 42} height={height || 43} />;
    case 'info':
      return <InfoSvg {...rest} className={overrideVariantClasses || fillIconClasses} width={width || 19} height={height || 19} />;
    case 'cog':
      return <CogSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 19} height={height || 18} />;
    case 'cart':
      return <CartSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 19} height={height || 18} />;
    case 'clock':
      return <ClockSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 19} height={height || 18} />;
    case 'eye-open':
      return <EyeOpenSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 19} height={height || 18} />;
    case 'next-to-talk':
      return <NextToTalk {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 19} height={height || 18} />;
    case 'minus-round':
      return <MinusRoundSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 19} height={height || 18} />;
    case 'right-arrow-round':
      return <RightArrowRoundSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 19} height={height || 18} />;
    case 'download':
      return <DownloadSvg {...rest} className={overrideVariantClasses || strokeIconClasses} width={width || 19} height={height || 18} />;

    default:
      return <></>;
  }
});
