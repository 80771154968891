import React, { useState, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../icon';
import { IconType } from '../icon';

type Tab = {
  title: string;
  content: ReactNode;
  icon: IconType;
  isLink?: boolean;
  href?: string;
};

type TabsProps = {
  tabs: Tab[];
  activeClass?: string;
  inactiveClass?: string;
};

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeClass = 'active text-white bg-charcoal-100',
  inactiveClass = 'text-charcoal-100 bg-white',
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const tabClassName = (index: number) => {
    return index === activeTab ? activeClass : inactiveClass;
  };

  // TODO: some of this styling is specific to the profile page use-case. It could be beneficial in the long-run to
  // split the tabs and tab content into separate components so that it's more reusable
  return (
    <div className="flex flex-col md:flex-row">
      {/*** Menu ***/}
      <div className="w-full md:w-1/5 flex mr-0 md:mr-6">
        <div className="flex md:flex-col flex-row bg-white w-full md:rounded-xl md:p-4 text-sm items-center h-fit border-b p-4">
          {tabs.map((tab, index) => {
            if (tab.isLink && tab.href) {
              return (
                <Link
                  key={index}
                  to={tab.href}
                  className={'cursor-pointer rounded-full py-2 md:px-4 inline-block w-full md:mb-2 last:mb-0 text-center ' + tabClassName(index)}
                >
                  <Icon icon={tab.icon} className="mr-1 stroke-[2px]" /> {tab.title}
                </Link>
              );
            } else {
              return (
                <button
                  key={index}
                  onClick={() => handleTabClick(index)}
                  className={'cursor-pointer rounded-full py-2 md:px-4 inline-block w-full md:mb-2 last:mb-0 ' + tabClassName(index)}
                >
                  <Icon icon={tab.icon} className="mr-1" /> {tab.title}
                </button>
              );
            }
          })}
        </div>
      </div>
      {/***********/}
      {/* Content */}
      <div className="w-full md:w-4/5">
        <div className="bg-white w-full sm:rounded-xl p-6 md:p-12">{tabs[activeTab].content}</div>
      </div>
      {/***********/}
    </div>
  );
};
