import { Button } from '@when-fertility/shared/domain/common';
import { GqlTestKitSummaryTestKitFragmentFragment, useCollectSampleButtonCollectSampleMutation } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React from 'react';
import { TestKitSummary } from '.';

type Props = {
  testKit: GqlTestKitSummaryTestKitFragmentFragment | null;
  isDisabled?: boolean;
};

export const CollectSampleButton = ({ testKit, isDisabled = false }: Props) => {
  const [markSampleCollected, { loading }] = useCollectSampleButtonCollectSampleMutation();

  const handleCollectSample = async () => {
    if (!testKit) {
      return;
    }

    await markSampleCollected({
      variables: {
        input: {
          testKitId: testKit?.id,
        },
      },
      onCompleted: () => {
        //
      },
      refetchQueries: [{ query: TestKitSummary.query.testKits }, 'TestKitSummaryGetTestKits'],
    });
  };

  if (!testKit) {
    return null;
  }

  return (
    <>
      <Button isDisabled={isDisabled || loading} onClick={handleCollectSample}>
        I&apos;ve finished this step
      </Button>
    </>
  );
};

CollectSampleButton.mutation = gql`
  mutation CollectSampleButtonCollectSample($input: MarkSampleAsCollectedInput!) {
    markSampleAsCollected(input: $input) {
      id
    }
  }
`;
