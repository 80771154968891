import React from 'react';
import usePlacesAutocomplete, { getGeocode, Suggestion } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { FormData, INPUT_CLASSES } from '../pages/register.page';
import { GqlAddressInput } from '@when-fertility/shared/gql/graphql';
import { UseFormRegister } from 'react-hook-form';
interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
export const PlacesAutocompleteComponent = ({
  disabled,
  register,
  setFormValue,
}: {
  register?: UseFormRegister<FormData>;
  disabled?: boolean;
  setFormValue: (address: GqlAddressInput) => void;
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: 'AU' }, // Restrict results to Australia
    },
    debounce: 300,
    cache: 24 * 60 * 60,
  });
  const ref = useOnclickOutside(() => {
    // When the user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: { target: { value: string } }) => {
    // @ts-ignore
    setFormValue(null);
    setValue(e.target.value);
  };

  const handleSelect = (suggestion: Suggestion) => () => {
    const { description, place_id } = suggestion;
    setValue(description, false);
    clearSuggestions();
    getGeocode({ placeId: place_id }).then((results) => {
      const { address_components: addressComponents } = results[0];
      const address1 = addressComponents.find((comp: GeocoderAddressComponent) => comp.types.includes('street_number'))?.long_name;
      const address2 = addressComponents.find((comp: GeocoderAddressComponent) => comp.types.includes('route'))?.long_name;
      const city = addressComponents.find((comp: GeocoderAddressComponent) => comp.types.includes('locality'))?.long_name;
      const province = addressComponents.find((comp: GeocoderAddressComponent) => comp.types.includes('administrative_area_level_1'))?.long_name;
      const province_code = addressComponents.find((comp: GeocoderAddressComponent) =>
        comp.types.includes('administrative_area_level_1')
      )?.short_name;
      const zip = addressComponents.find((comp: GeocoderAddressComponent) => comp.types.includes('postal_code'))?.long_name;
      const country = addressComponents.find((comp: GeocoderAddressComponent) => comp.types.includes('country'))?.long_name;
      const address: GqlAddressInput = { address1, address2, city, province, province_code, zip, country };
      setFormValue(address);
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} className={'hover:text-white hover:cursor-pointer max-w-[400px]'} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div className={'mb-3'} ref={ref}>
      <input className={INPUT_CLASSES} placeholder="Address" disabled={!ready || disabled} onChange={handleInput} value={value} />
      <input className={'hidden'} {...(register && register('address', { required: 'Address is required' }))} />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <div className={'flex flex-row pl-5'}>
          <ul>{renderSuggestions()}</ul>
        </div>
      )}
    </div>
  );
};
