import React, { ReactNode, memo } from 'react';
import cn from 'classnames';

type Props = {
  children: ReactNode;
  className?: string;
  variant?: 'light' | 'dark';
  noPadding?: boolean;
};

const BASE_CLASSES = ['border', 'rounded-2xl', 'overflow-hidden', 'border-silver-100'];
export const Card = memo(({ children, className, variant = 'light', noPadding = false, ...rest }: Props) => {
  const classes = cn(className, BASE_CLASSES, {
    'bg-white': variant === 'light',
    'bg-silver-100': variant === 'dark',
    'p-6 md:p-8': !noPadding,
  });
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
});
