import React from 'react';

interface Props {
  indexValue: string;
  header: string;
  children: React.ReactNode;
}

export const NextStepItem = ({ indexValue, header, children }: Props) => {
  return (
    <div className="border-t border-silver-100 first:border-t-0">
      <div className="flex flex-wrap w-full mt-8">
        <div className="rounded-full border-solid border-silver-100 border min-w-16 h-10 flex-none mr-4">
          <p className="ml-4 mt-2 text-s lg:w-16 md:w-24">{indexValue}</p>
        </div>
        <p className="text-base w-32 ml-2 lg:flex-none">{header}</p>
        {children}
      </div>
      <div className="mb-8" />
    </div>
  );
};
