import React from 'react';
import { InlineWidget } from 'react-calendly';

const CalendlyApp = () => {
  return (
    <div className="App">
      <InlineWidget
        url="https://calendly.com/rachana-desai?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=F9F7F6&text_color=565454&primary_color=737057"
        // Option if we choose to show calendar instead of the event
        // url="https://calendly.com/rachana-desai/book-appointment?month=2023-03&date=2023-03-28"
        styles={{
          height: '650px',
        }}
      />
    </div>
  );
};

export default CalendlyApp;
