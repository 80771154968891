import React from 'react';
import { AUTH_ROUTES } from 'domain/auth';
import { Icon, HeaderNavLink } from '@when-fertility/shared/domain/common';
import { DASHBOARD_ROUTES } from 'domain/dashboard';

import { Link } from 'react-router-dom';

export const UnauthenticatedNav = () => {
  return (
    <div className="absolute sticky top-0 z-50 bg-silver-100 bg-transparent border-b border-b-charcoal-60">
      <div className="flex justify-between w-full items-center px-4 h-nav-mobile md:h-nav-desktop">
        <div className="flex items-center">
          <Link to={DASHBOARD_ROUTES.root}>
            <div className="hidden md:block">
              <Icon icon={'when'} height={30} width={100} />
            </div>
            <div className="block md:hidden">
              <Icon icon={'when'} height={30} width={100} />
            </div>
          </Link>
        </div>
        <div>
          <div className="flex h-8 text-xs">
            <HeaderNavLink to={AUTH_ROUTES.login} className="flex items-center bg-white rounded-full px-4">
              Log in
            </HeaderNavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
