import React, { HTMLProps, ChangeEvent } from 'react';
import cn from 'classnames';

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'required' | 'disabled'> & {
  onChange: (event: ChangeEvent<HTMLInputElement>) => any;
  isDisabled?: boolean;
  isError?: boolean;
  isRequired?: boolean;
};

const BASE_CLASSES = [
  'block',
  'placeholder-charcoal-40',
  'relative',
  'rounded-full',
  'w-full',
  'px-4',
  'leading-9',
  'border',
  'focus:outline-none',
  'min-h-input',
];

export const Input = ({ className, onChange, type = 'text', isError, isDisabled, isRequired, ...rest }: Props) => {
  const classes = cn(className, BASE_CLASSES, {
    'border-red-300 placeholder-red-200 focus:border-red-300 focus:shadow-outline-red': isError,
    'border-silver-100 focus:border-charcoal-100': !isError,
    'opacity-50': isDisabled,
  });

  return <input {...rest} className={classes} onChange={onChange} type={type} disabled={isDisabled} required={isRequired} />;
};
