import React, { ReactNode, useMemo } from 'react';
import { TestKitSummaryStep } from './test-kit-summary.component';
import cn from 'classnames';
import { BlobType, BlobIcon, Icon, IconType } from '@when-fertility/shared/domain/common/components';

export type TestKitSummaryStepStatus = 'completed' | 'currentStep' | 'futureStep';

type Props = {
  title: string;
  expandedItem: TestKitSummaryStep | null;
  setExpandedItem: (value: TestKitSummaryStep | null) => any;
  stepKey: TestKitSummaryStep;
  children?: ReactNode;
  stepStatus: TestKitSummaryStepStatus;
};

const StatusIconMap: Record<TestKitSummaryStepStatus, IconType | null> = {
  completed: 'check',
  currentStep: 'refresh',
  futureStep: null,
};

const StepIconMap: Record<TestKitSummaryStep, BlobType> = {
  activate: '1',
  quiz: '2',
  collect: '3',
  prepare: '4',
  results: '5',
};

export const TestKitSummaryItem = ({ title, expandedItem, setExpandedItem, stepKey, children, stepStatus }: Props) => {
  const isExpanded = useMemo(() => expandedItem === stepKey, [expandedItem, stepKey]);
  const classes = cn('border-t first:border-0 p-6 md:p-8 border-silver-100', {
    'first:rounded-t-xl last:rounded-b-xl border-silver-100': stepStatus === 'completed',
  });

  return (
    <div className={classes}>
      <div
        onClick={() => {
          if (stepStatus === 'futureStep') {
            return;
          }
          setExpandedItem(expandedItem === stepKey ? null : stepKey);
        }}
        className={cn('flex justify-between items-center', { 'hover:cursor-pointer': stepStatus !== 'futureStep' })}
      >
        <div className="flex flex-row justify-between items-center">
          <BlobIcon variant={stepStatus === 'completed' ? 'filled' : 'outline'} icon={StatusIconMap[stepStatus]} blobType={StepIconMap[stepKey]} />
          <div className="flex flex-col ml-3 sm:ml-7">
            {stepStatus === 'currentStep' && <div className="text-sm text-charcoal-40">In progress</div>}
            <div>{title}</div>
          </div>
        </div>
        <Icon className={cn({ hidden: stepStatus === 'futureStep' })} icon={isExpanded ? 'minus' : 'plus'} />
      </div>
      <div
        className={`ml-12 sm:ml-16 last:ml-4 w-5/6 overflow-hidden transition-all duration-300 transition-max-height  ${
          !isExpanded ? 'max-h-0 px-2' : 'max-h-[1500px] p-2'
        }`}
      >
        {children}
      </div>
    </div>
  );
};
