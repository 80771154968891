import React, { memo, ReactNode } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

export type HeaderNavLinkType = {
  children: ReactNode;
  className?: string;
  to?: string;
  onClick?: () => void;
  size?: 'extra-small' | 'small' | 'base' | 'large';
};

const BASE_CLASSES = ['sm:ml-6', 'first:ml-0'];
const ACTIVE_CLASSES = ['underline'];

export const HeaderNavLink = memo(({ children, className, to, onClick, size = 'small' }: HeaderNavLinkType) => {
  const baseClasses = cn(className, BASE_CLASSES, {
    'text-xs': size === 'extra-small',
    'text-sm': size === 'small',
    'text-lg': size === 'large',
    'text-base': size === 'base',
  });
  const activeClasses = cn(ACTIVE_CLASSES);

  return (
    <NavLink className={baseClasses} to={to} onClick={onClick && onClick}>
      {({ isActive }) => <span className={isActive ? activeClasses : ''}>{children}</span>}
    </NavLink>
  );
});
