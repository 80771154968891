import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { useDashboardPageGetUserQuery } from '@when-fertility/shared/gql/graphql';
import { Link } from 'react-router-dom';
import { BlobIcon, Card, Icon, Page } from '@when-fertility/shared/domain/common';
import { RESULT_ROUTES } from '../results';
import { USER_ROUTES } from '../user';
import { TestKitSummary } from './components/test-kit-summary.component';

export const DashboardPage = () => {
  const { data } = useDashboardPageGetUserQuery();

  const { firstName } = useMemo(() => {
    return {
      firstName: data?.userProfile.firstName || '',
    };
  }, [data]);
  return (
    <>
      <Page
        title={`Welcome ${firstName}`}
        headerClassName="bg-wave-sky-mobile sm:bg-wave-sky-standard 2xl:bg-wave-sky-wide bg-bottom bg-cover bg-no-repeat h-40 sm:h-64 text-white"
        hasPadding={true}
      >
        <div className="flex mt-10 sm:mt-2 sm:my-8 flex-col md:flex-row md:justify-between gap-7">
          <div className="w-full md:w-2/3">
            <TestKitSummary />
          </div>
          <div className="w-full md:w-1/3">
            <div className="mb-6">
              <Card>
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <BlobIcon icon="result" variant="filled" blobType="6" iconHeight={18} iconWidth={17} />
                    <div className="ml-3">Results</div>
                  </div>
                  <Link to={RESULT_ROUTES.root}>
                    <Icon icon="arrow-right" width={30} height={23}></Icon>
                  </Link>
                </div>
                <div className="text-sm mt-4">Once your results are available, you&apos;ll find them here.</div>
              </Card>
            </div>
            <div className="mb-6">
              <Card>
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <BlobIcon icon="user" variant="filled" blobType="7" iconHeight={18} iconWidth={14} />
                    <div className="ml-3">My Profile</div>
                  </div>
                  <Link to={USER_ROUTES.profile}>
                    <Icon icon="arrow-right" width={30} height={23}></Icon>
                  </Link>
                </div>
                <div className="text-sm mt-4">Keep your profile up to date to make sure you&apos;re getting the most personalised experience.</div>
              </Card>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

DashboardPage.fragments = {
  user: gql`
    fragment DashboardPageUserFragment on User {
      id
      firstName
      lastName
    }
  `,
};

DashboardPage.query = gql`
  ${DashboardPage.fragments.user}
  query DashboardPageGetUser {
    userProfile {
      ...DashboardPageUserFragment
    }
  }
`;
