import { SectionSeparator } from './section-separator';
import { Button } from '@when-fertility/shared/domain/common';
import React from 'react';
import { ResultsProps } from '@when-fertility/shared/domain/results/components/section-your-results';
import { GqlResult, GqlResultReferenceRange } from '@when-fertility/shared/gql/graphql';
import { REFERENCE_RANGE_MAP } from '@when-fertility/shared/domain/test-kit';
import { NavLink } from 'react-router-dom';

export const NextSteps = ({ results }: ResultsProps) => {
  const getRetestDuration = (refRange: GqlResultReferenceRange, ageGroup: string) => {
    if (refRange === 'LOW' || refRange === 'PERCENTILE_2_5') {
      return '12 months';
    } else if (refRange === 'IN_RANGE' || refRange === 'HIGH') {
      return ageGroup === '25-29' || ageGroup === '30-34' ? '24 months' : '12 months';
    }
    return '';
  };

  const getArticle = (refRange: GqlResultReferenceRange) => {
    if (refRange === 'LOW' || refRange === 'PERCENTILE_2_5') {
      return 'https://www.whenfertility.com.au/when-matters/articles/my-amh-is-low-now-what';
    } else if (refRange === 'IN_RANGE') {
      return 'https://www.whenfertility.com.au/when-matters/articles/my-amh-level-is-in-range-now-what';
    } else if (refRange === 'HIGH') {
      return 'https://www.whenfertility.com.au/when-matters/articles/my-amh-level-is-high-now-what';
    }
  };

  return (
    <div className="h-auto bg-next-steps-standard bg-top bg-cover bg-no-repeat">
      <SectionSeparator
        title="Next steps"
        number="03"
        seperatorType={'transparent'}
        subTitle={'Here are some things we recommend doing now that you know your result.'}
      />
      {results &&
        results?.map((result: GqlResult, index) => {
          const report = result.report!;
          const referenceRangeName: string = REFERENCE_RANGE_MAP[report.referenceRange];
          const ageGroup: string = report.ageGroup;
          let inflectedReferenceRangeName = '';
          if (referenceRangeName === 'In-range') {
            inflectedReferenceRangeName = 'an ' + referenceRangeName;
          } else {
            inflectedReferenceRangeName = 'a ' + referenceRangeName;
          }
          return (
            <div key={index} className="flex flex-col md:flex-row justify-around lg:px-40 pb-10">
              <div className="relative mb-5 md:w-1/3 h-96 md:h-108 lg:h-96 mx-4 lg:mx-0 rounded-md bg-white bg-opacity-70 p-10">
                <div className="text-3xl w-full">Learn more about your {referenceRangeName.toLowerCase()} result</div>
                <div className="w-full mt-6">
                  Dive into more information written by our Chief Medical Advisors about what {inflectedReferenceRangeName.toLowerCase()} result means
                  in the overall fertility picture.{' '}
                </div>
                <Button className="absolute bottom-0 mb-10" variant="secondary" size="regular" isExternal>
                  <NavLink to={getArticle(report.referenceRange)}>Read article</NavLink>
                </Button>
              </div>
              <div className="relative mb-5 md:w-1/3 h-96 md:h-108 lg:h-96 mx-4 rounded-md bg-white bg-opacity-70 p-10">
                <div className="text-3xl w-full">Monitor and track your AMH</div>
                <div className="w-full mt-6">
                  Now that you know your egg count, it’s important to continue to track your AMH over time to make sure you’re on top of any big
                  changes before they happen. Since your result is currently {referenceRangeName.toLowerCase()}, we recommend you retest in{' '}
                  {getRetestDuration(report.referenceRange, ageGroup)} time. We’ll send you a reminder just before then.{' '}
                </div>
              </div>
              <div className="relative md:w-1/3 h-96 md:h-108 lg:h-96 mx-4 rounded-md bg-white bg-opacity-70 p-10">
                <div className="text-3xl w-full">Discover Real Stories on WHEN Matters</div>
                <div className="w-full mt-6">Read about other community members' stories and remember that we are all on this journey, together.</div>
                <Button className="absolute bottom-0 mb-10" variant="secondary" size="regular" isExternal>
                  <NavLink to={'https://www.whenfertility.com.au/when-matters/category/real-stories'}>Discover</NavLink>
                </Button>
              </div>
            </div>
          );
        })}
    </div>
  );
};
