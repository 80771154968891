import * as Sentry from '@sentry/react';

class SentryService {
  isInitialised = false;

  init = () => {
    if (!this.isInitialised && process.env.CLIENT_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.CLIENT_SENTRY_DSN,
        integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
        environment: process.env.ENVIRONMENT,
        attachStacktrace: true,
        enableTracing: true,
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
      console.log('Sentry initialised');

      this.isInitialised = true;
    }
  };
}

export const sentryService = new SentryService();
