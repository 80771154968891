import { GqlTestKit, GqlTestKitStatus } from '@when-fertility/shared/gql/graphql';

class TestKitService {
  getIsTestKitActivated = (testKitStatus: GqlTestKitStatus | null | undefined) =>
    Boolean(testKitStatus && ['SUBMITTED', 'OPEN', 'SPECIMEN_RECEIVED', 'ACTIVATED', 'CLOSED'].includes(testKitStatus));

  getLatestTestKit = <T>(testKits: (Pick<GqlTestKit, 'id' | 'status'> & T)[] | undefined) => {
    if (!testKits?.length) {
      return null;
    }
    const activatedTestKits = testKits.filter((kit) => this.getIsTestKitActivated(kit.status));

    return activatedTestKits.length ? activatedTestKits[0] : null;
  };
}

export const testKitService = new TestKitService();
