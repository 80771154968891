import React from 'react';
import { AUTH_ROUTES } from 'domain/auth';
import { Page, Tabs } from '@when-fertility/shared/domain/common';
import { ProfileSummary } from '../components/profile-summary.component';
import { SettingsSummary } from '../components/setting-summary.component';
import { PatientProfileProvider } from '../patient-profile.provider';

export const UserProfilePage = () => {
  return (
    <>
      <Page hasPadding={false} className="bg-white md:bg-silver-40" headerClassName="bg-silver-100 md:bg-transparent pb-8">
        <div className="pb-12 lg:px-24">
          <div className="pl-5 md:pl-0 text-3xl md:text-5xl pt-12 md:pt-20 pb-6 max-w-7xl w-full text-charcoal">My Account</div>
          <Tabs
            tabs={[
              {
                title: 'Profile',
                icon: 'user',
                content: (
                  <>
                    <PatientProfileProvider allowNextOnChange={false}>
                      <ProfileSummary />
                    </PatientProfileProvider>
                  </>
                ),
              },
              // todo: hide it for now until we are supplied with new brand color
              // { title: 'TestKits', icon: 'cart', content: <TestKitHistory /> },
              { title: 'Settings', icon: 'cog', content: <SettingsSummary /> },
              { title: 'Logout', content: 'Logout content', icon: 'arrow-right', isLink: true, href: `${AUTH_ROUTES.logout}` },
            ]}
          />
        </div>
      </Page>
    </>
  );
};
