import * as Sentry from '@sentry/react';
import { useAuth, AUTH_ROUTES } from 'domain/auth';
import { ErrorComponent } from '@when-fertility/shared/domain/common';
import { Nav } from '../../common';
import { localStorageService } from '@when-fertility/shared/domain/common/services';
import { useCurrentUser } from 'domain/user';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const AuthenticatedLayout = () => {
  const { isAuthenticating, isAuthenticated, firebaseUser } = useAuth();
  const { role } = useCurrentUser();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticating) {
          return;
        }

        if (!isAuthenticated || !firebaseUser) {
          const currentPath = [pathname, searchParams.toString()].join('?');
          localStorageService.setItem('redirect', currentPath);
          //if user is not authenticated and go to dob page, direct them to register page instead of login page
          if (currentPath.includes('/dob')) {
            navigate(AUTH_ROUTES.register);
          } else {
            navigate(AUTH_ROUTES.login);
          }
        }

        setIsLoading(false);
      } catch (error: any) {
        Sentry.captureException(error);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, [isAuthenticating, isAuthenticated]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <ErrorComponent isPage />;
  }

  return (
    <>
      {role === 'PATIENT' && <Nav />}
      <Outlet />
    </>
  );
};
