import React, { HTMLProps, ChangeEvent } from 'react';
import cn from 'classnames';

type Props = Omit<HTMLProps<HTMLTextAreaElement>, 'onChange' | 'required' | 'disabled'> & {
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => any;
  isDisabled?: boolean;
  isError?: boolean;
  placeholder?: string;
};

const BASE_CLASSES =
  'block placeholder-charcoal-40 relative leading-9 focus:outline-none rounded-md w-full h-24 p-4 resize-none border border-silver-100 bg-silver-20';

export const TextArea = ({ className, onChange, isError, isDisabled, placeholder = 'Write notes here...', ...rest }: Props) => {
  const classes = cn(className, BASE_CLASSES, {
    'border-red-300 placeholder-red-200 focus:border-red-300 focus:shadow-outline-red': isError,
    'border-silver-100 focus:border-charcoal-100': !isError,
    'opacity-50': isDisabled,
  });

  return <textarea {...rest} onChange={onChange} className={classes} placeholder={placeholder} />;
};
