import { Button } from '@when-fertility/shared/domain/common';
import { useUserProfile } from 'domain/quiz';
import { QuizQuestion } from 'domain/quiz/components/quiz-question.component';
import React, { useState } from 'react';

type Props = {
  onFinished: () => void;
};

export const EditProfileField = ({ onFinished }: Props) => {
  const { save, resetFormData } = useUserProfile();
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    await save();
    setIsSaving(false);
    onFinished();
  };

  const handleCancel = () => {
    resetFormData();
    onFinished();
  };

  return (
    <div>
      <QuizQuestion setShowNextButton={() => false} />
      <div className="flex flex-col w-full mt-6">
        <Button onClick={handleSave} isDisabled={isSaving}>
          Save
        </Button>
        <Button onClick={handleCancel} className="mt-2" variant="secondary">
          Cancel
        </Button>
      </div>
    </div>
  );
};
