import React, { useMemo, useState } from 'react';
import { BookedAppointmentButton, MasterclassVideo, NextStepItem } from '.';
import { GqlResult, GqlResultReferenceRange, GqlTestKitSummaryTestKitFragmentFragment } from '@when-fertility/shared/gql/graphql';
import { FullScreenModalComponent } from '@when-fertility/shared/domain/common';
import { isLowAMH, REFERENCE_RANGE_MAP } from '@when-fertility/shared/domain/test-kit/test-kit.utils';
import { DownloadReportButton } from '@when-fertility/shared/domain/results';

interface Props {
  result: GqlResult | undefined;
  testKit: GqlTestKitSummaryTestKitFragmentFragment | undefined;
}

export const NextStepSection = ({ result, testKit }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const hasHadAppointmentBooked = useMemo(() => Boolean(['yes', 'no'].includes(testKit?.apptBookedAnswer)), [testKit]);
  const isLowAMHResult = useMemo(() => isLowAMH(result), [result]);
  const referenceRangeName: string = REFERENCE_RANGE_MAP[result?.report?.referenceRange];
  const ageGroup: string | undefined = result?.report?.ageGroup;
  const monitorTrackIndex = useMemo(() => (isLowAMHResult ? '03' : '02'), [isLowAMHResult]);
  const healthPractitionerIndex = useMemo(() => (isLowAMHResult ? '04' : '03'), [isLowAMHResult]);
  const getRetestDuration = (refRange: GqlResultReferenceRange | undefined, ageGroup: string) => {
    if (refRange === 'LOW' || refRange === 'PERCENTILE_2_5') {
      return '12 months';
    } else if (refRange === 'IN_RANGE' || refRange === 'HIGH') {
      return ageGroup === '25-29' || ageGroup === '30-34' ? '24 months' : '12 months';
    }
    return '';
  };
  const onCloseModal = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  return (
    <div className="overflow-visible">
      <NextStepItem indexValue="01" header="Hear more about your result from our Chief Medical Advisors">
        <MasterclassVideo referenceRange={result?.report?.referenceRange} />
      </NextStepItem>
      {isLowAMHResult && (
        <NextStepItem indexValue="02" header="Would you like an appointment with a WHEN Fertility Nurse?">
          <div className="mt-8 lg:mt-0 w-64 lg:ml-24 lg:w-72">
            {!hasHadAppointmentBooked && (
              <>
                <div className="mb-4 flex flex-row items-center">
                  <BookedAppointmentButton testKit={testKit} text="Yes" answer="yes" setIsModalOpen={setIsModalOpen} />
                  <p className="ml-4 text-sm">Let's find a timeslot that works.</p>
                </div>
                <div className="mb-4 border-b border-silver-100" />
                <div className="flex flex-row items-center">
                  <BookedAppointmentButton testKit={testKit} text="No" answer="no" />
                  <p className="ml-4 text-sm">Maybe next time.</p>
                </div>
              </>
            )}
            {hasHadAppointmentBooked && testKit?.apptBookedAnswer === 'yes' && (
              <div className="mb-4 flex flex-row items-center">
                <p className="ml-4 text-sm">
                  Great! Our fertility nurse is looking forward to seeing you soon. You can reach out at{' '}
                  <a href="mailto:hello@whenfertility.com.au">hello@whenfertility.com.au</a> if you need any help with your booking.
                </p>
              </div>
            )}
            {hasHadAppointmentBooked && testKit?.apptBookedAnswer === 'no' && (
              <div className="mb-4 flex flex-row items-center">
                <p className="ml-4 text-sm">
                  No worries, if you change your mind you can always reach out at{' '}
                  <a href="mailto:hello@whenfertility.com.au">hello@whenfertility.com.au</a>
                </p>
              </div>
            )}
          </div>
        </NextStepItem>
      )}
      <NextStepItem indexValue={monitorTrackIndex} header="Monitor and track your AMH">
        <div className="mt-8 lg:mt-0 ml-20 md:ml-32 lg:w-72 lg:ml-24">
          <p className="text-sm">It's important to continue to track your AMH so you're across how it's moving over time.</p>
          <p className="text-sm mt-4">
            Since your result is currently {referenceRangeName?.toLowerCase()}, we recommend you retest in{' '}
            {getRetestDuration(result?.report?.referenceRange, ageGroup)} time. We’ll send you a reminder just before then.{' '}
          </p>
        </div>
      </NextStepItem>
      <NextStepItem indexValue={healthPractitionerIndex} header="Download your Health Practitioner Report">
        <div className="mt-4 lg:mt-0 ml-20 md:ml-32 lg:w-72 lg:ml-24 items-center">
          {testKit?.releaseReport ? <DownloadReportButton size="regular" testKitId={testKit?.id} reportType={'HEALTH_PRACTITIONER_REPORT'} /> : ''}
          <p className="mt-6 text-sm">
            Take this report to your next appointment with your Health Practitioner so they're across your results and you can continue your fertility
            conversation.{' '}
          </p>
        </div>
      </NextStepItem>
      <FullScreenModalComponent isOpen={isModalOpen} setIsOpen={onCloseModal}>
        <iframe
          className="w-full md:w-5/6"
          style={{ height: '1000px' }}
          scrolling="no"
          src="https://scheduler.zoom.us/when-fertility-appointments/tele-education-appointment?embed=true"
        ></iframe>
      </FullScreenModalComponent>
    </div>
  );
};
