export const AMH_REFERENCE_RANGES: { [key: string]: { PERCENTILE_2_5: number; LOW: number; HIGH: number } } = {
  '25-29': { PERCENTILE_2_5: 6.35, LOW: 8.57, HIGH: 64.6 },
  '30-34': { PERCENTILE_2_5: 4.11, LOW: 5.08, HIGH: 54.2 },
  '35-39': { PERCENTILE_2_5: 1.05, LOW: 2.89, HIGH: 49.7 },
  '40-44': { PERCENTILE_2_5: 0.193, LOW: 0.421, HIGH: 31.7 },
  '45-50': { PERCENTILE_2_5: 0.071, LOW: 0.071, HIGH: 12.8 },
};

export const REPORT_NOTES = {
  low_hormonal_contraception:
    'Your hormonal contraception use may have artificially lowered your AMH levels. We recommend discussing the benefits of retesting your AMH while off hormonal contraception to confirm your low result with your health practitioner.',
  low_recent_hormonal_contraception:
    'Your recent hormonal contraception use may have artificially lowered your AMH levels. We recommend waiting until it has been 3 months since you stopped use to retest your AMH to confirm your low result. Remember, its important to always use a reliable form of contraception if you don’t want to become pregnant.',
  low_only_applies_no_hormone: 'This report only applies if your result has not been artificially lowered by your hormonal contraception use.',
  low_only_applies_no_recent_hormone:
    'This report only applies if your result has not been artificially lowered by your recent hormonal contraception use.',
  low_lower_eggs_than_expected: 'Your AMH is low which means you are estimated to have a lower number of eggs than expected for your age.',
  low_shorter_reproductive_window: 'You may have a shorter reproductive window and run out of eggs earlier than expected.',
  low_ivf_fewer_eggs:
    'In any cycle of egg freezing or IVF, you are likely to retrieve fewer eggs than what would be expected for someone of your age. This means it could take more cycles to retrieve the target number of eggs.',
  low_doesnt_predict_fertility:
    'We recommend that you download the Health Practitioner’s Report and take it to your Health Practitioner for further discussion about your fertility and family planning goals.',
  very_low_talk_to_a_doctor:
    'As your result is near the bottom of the low range, we recommend that you download the Health Practitioner’s Report and take it to your Health Practitioner for further discussion about your fertility. If you have family planning goals, you should request a Fertility Specialist referral.',
  in_range_expected: 'Your AMH is in-range which means you are estimated to have the number of eggs that would be expected for someone of your age.',
  in_range_ivf_expected_eggs:
    'In any cycle of egg freezing or IVF, you are likely to retrieve the number of eggs that would be expected for someone of your age.',
  egg_quality: (age: number) => {
    if (age >= 35 && age <= 39) {
      return `By the age of ${age}, your egg quality is likely starting to decrease and becomes more of a consideration in your overall fertility picture.`;
    } else if (age >= 40) {
      return `By the age of ${age}, your egg quality would likely be low which can make becoming pregnant harder. Low egg quality can also make a successful egg freezing or IVF cycle more difficult.`;
    }
  },
  high_more_eggs_expected: 'Your AMH is high which means you are estimated to have more eggs than expected for your age.',
  high_ivf_more_eggs:
    'In any cycle of egg freezing or IVF, you have a higher potential for eggs being collected than would be expected for your age.',
  high_polycystic:
    'High ovarian reserve has been associated with polycystic ovary syndrome (PCOS) but does not diagnose PCOS by itself. Other symptoms of PCOS include irregular or absent periods, excessive body hair, acne, hair loss or difficulty maintaining weight. If you experience one or more of these symptoms, it’s important that you download your Health Practitioner’s Report and take it to your Health Practitioner for further PCOS investigation.',
  very_high_talk_to_a_doctor:
    'High ovarian reserve has been associated with polycystic ovary syndrome (PCOS). Since your AMH levels are near the top of the high range, it’s important that you download your Health Practitioner’s Report and take it to your Health Practitioner for further PCOS investigation.',
  family_planning_talk_to_a_doctor:
    'If you have family planning goals, we recommend downloading the Health Practitioner’s Report and taking it to your Health Practitioner for further discussion.',
};
