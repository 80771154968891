import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Page, HeaderNavLink } from '@when-fertility/shared/domain/common';
import { URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import { ActivateActivationCode, ACTIVATION_CODE_DIGITS } from './components/activate-activation-code';

export const ActivatePage = () => {
  const [activationCodeValidated, setActivationCodeValidated] = useState(false);
  const [activationCode, setActivationCode] = useState('');
  const [isPrepopulated, setIsPrepopulated] = useState(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const urlActivationCode = searchParams.get(URL_PARAMETERS.activationCode);
    if (urlActivationCode) {
      setActivationCode(urlActivationCode?.substring(0, ACTIVATION_CODE_DIGITS) || ''); // Only take first 7 characters
      setIsPrepopulated(true);
    }
    // TODO: automatically activate?
  }, [searchParams]);

  return (
    <Page isCentred className="bg-blob bg-center bg-no-repeat bg-[length:130%] md:bg-[length:auto]" variant="medium">
      <div className="p-2 sm:p-8 w-96 sm:w-100">
        {!activationCodeValidated && (
          <ActivateActivationCode
            activationCode={activationCode}
            setActivationCode={setActivationCode}
            checkCodeOnLoad={false}
            isPrepopulated={isPrepopulated}
            onSuccess={() => setActivationCodeValidated(true)}
          />
        )}
        {activationCodeValidated && (
          <>
            <div className="text-4xl mb-3 text-center">One last thing</div>
            <div className="mb-3 pl-5 px-3">Before we finish activating your kit, we need you to confirm that:</div>
            <ul className="list-disc pl-5">
              <li>You'll collect your sample in the next hour</li>
              <li>You'll post your sample today</li>
              <li>You haven't taken any supplements containing biotin in the last 3 days.</li>
            </ul>

            <Button className="w-full mt-3 min-h-medium-button" type="submit">
              <HeaderNavLink size="small" to={`/dob?code=${activationCode}`}>
                Yes, activate my kit
              </HeaderNavLink>
            </Button>
            <Button className="w-full mt-2 min-h-medium-button">
              <HeaderNavLink size="small" to={`https://www.whenfertility.com.au/help`}>
                No, I'll activate later
              </HeaderNavLink>
            </Button>
          </>
        )}
      </div>
    </Page>
  );
};

ActivatePage.fragments = {
  user: gql`
    fragment ActivatePageUserFragment on User {
      id
      dateOfBirth
    }
  `,
};

ActivatePage.mutation = gql`
  mutation ActivateTestKit($input: ActivateTestKitInput!) {
    activateTestKit(input: $input) {
      id
    }
  }
`;

ActivatePage.query = gql`
  ${ActivatePage.fragments.user}

  query ActivatePageGetUser {
    userProfile {
      ...ActivatePageUserFragment
    }
  }
`;
