import React, { AnchorHTMLAttributes, HTMLProps, memo, MouseEvent as ReactMouseEvent } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

type AnchorClickEvent = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
type ButtonClickEvent = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;

type Props = Omit<HTMLProps<HTMLButtonElement>, 'disabled' | 'size'> & {
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'regular';
  onClick?: AnchorClickEvent | ButtonClickEvent;
  isDisabled?: boolean;
  isExternal?: boolean;
  isLink?: boolean;
  isOpenedInNewTab?: boolean;
};

const BASE_CLASSES = ['rounded-full', 'justify-center', 'leading-5', 'text-sm', 'border', 'border-charcoal-100'];

export const Button = memo(
  ({
    children,
    className,
    href,
    onClick,
    type = 'button',
    variant = 'primary',
    size = 'regular',
    isDisabled,
    isExternal,
    isLink,
    isOpenedInNewTab,
    ...rest
  }: Props) => {
    const needWhiteText = () => {
      if (variant === 'primary') {
        if (className && className.includes('text-charcoal') && !className.includes('hover:text-charcoal')) {
          return false;
        }
        return true;
      }
    };

    const classes = cn(className, BASE_CLASSES, {
      'pointer-events-none opacity-10': isDisabled,
      'bg-charcoal-100 sm:hover:bg-transparent sm:hover:border sm:hover:border-charcoal-100 sm:hover:text-charcoal-100': variant === 'primary',
      'bg-transparent text-charcoal-100 sm:hover:bg-charcoal-100 sm:hover:text-white': variant === 'secondary',
      'py-2 px-4': size === 'regular',
      'py-1 px-3 text-xs': size === 'small',
      'text-white': needWhiteText(),
    });

    if (isLink && href) {
      if (isExternal) {
        const additionalAttrs: AnchorHTMLAttributes<HTMLAnchorElement> = {};

        if (isOpenedInNewTab) {
          additionalAttrs.target = '_blank';
          additionalAttrs.rel = 'noreferrer';
        }

        return (
          <a {...additionalAttrs} className={classes} href={href} onClick={onClick as AnchorClickEvent}>
            {children}
          </a>
        );
      }

      return (
        <Link className={classes} to={href} onClick={onClick as AnchorClickEvent}>
          {children}
        </Link>
      );
    }

    return (
      <button {...rest} className={classes} type={type} disabled={isDisabled} onClick={onClick as ButtonClickEvent}>
        {children}
      </button>
    );
  }
);
