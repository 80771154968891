import { Button } from '@when-fertility/shared/domain/common';
import { GqlTestKitSummaryTestKitFragmentFragment, useViewedResultButtonViewedResultMutation } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React from 'react';
import { TestKitSummary } from '.';

type Props = {
  testKit: GqlTestKitSummaryTestKitFragmentFragment | null | undefined;
  isDisabled?: boolean;
};

export const ViewedResultButton = ({ testKit, isDisabled = false }: Props) => {
  const [markHasViewedResult, { loading }] = useViewedResultButtonViewedResultMutation();

  const handleViewedResult = async () => {
    if (!testKit) {
      return;
    }

    await markHasViewedResult({
      variables: {
        input: {
          testKitId: testKit?.id,
        },
      },
      onCompleted: () => {
        //
      },
      refetchQueries: [{ query: TestKitSummary.query.testKits }, 'TestKitSummaryGetTestKits'],
    });
  };

  if (!testKit) {
    return null;
  }

  return (
    <>
      <Button isDisabled={isDisabled || loading} onClick={handleViewedResult}>
        I&apos;ve finished this step
      </Button>
    </>
  );
};

ViewedResultButton.mutation = gql`
  mutation ViewedResultButtonViewedResult($input: MarkHasViewedResultInput!) {
    markHasViewedResult(input: $input) {
      id
    }
  }
`;
