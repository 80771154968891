import { Button } from '@when-fertility/shared/domain/common';
import {
  GqlTestKitSummaryTestKitFragmentFragment,
  useHasHadAppointmentBookedButtonAppointmentBookedMutation,
} from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React from 'react';
import { TestKitSummary } from '.';

type Props = {
  testKit: GqlTestKitSummaryTestKitFragmentFragment | null | undefined;
  answer: string;
  isDisabled?: boolean;
  text?: string;
  setIsModalOpen?: void | undefined;
};

export const BookedAppointmentButton = ({
  testKit,
  answer,
  isDisabled = false,
  text = "I've finished this step",
  setIsModalOpen = undefined,
}: Props) => {
  const [markHasHadAppointmentBooked, { loading }] = useHasHadAppointmentBookedButtonAppointmentBookedMutation();

  const handleBookedAppointment = async () => {
    if (!testKit) {
      return;
    }

    await markHasHadAppointmentBooked({
      variables: {
        input: {
          testKitId: testKit?.id,
          apptBookedAnswer: answer,
        },
      },
      onCompleted: () => {
        //
      },
      refetchQueries: [{ query: TestKitSummary.query.testKits }, 'TestKitSummaryGetTestKits'],
    });

    if (setIsModalOpen) {
      setIsModalOpen(true);
    }
  };

  if (!testKit) {
    return null;
  }

  return (
    <>
      <Button isDisabled={isDisabled || loading} onClick={handleBookedAppointment}>
        {text}
      </Button>
    </>
  );
};

BookedAppointmentButton.mutation = gql`
  mutation HasHadAppointmentBookedButtonAppointmentBooked($input: MarkHasHadAppointmentBookedInput!) {
    markHasHadAppointmentBooked(input: $input) {
      id
      apptBookedAnswer
    }
  }
`;
