import React from 'react';
import { Icon } from '../icon';
import cn from 'classnames';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children: React.ReactNode;
  customHeader?: React.ReactNode;
  title?: string;
};

export const Modal = ({ isOpen, setIsOpen, children, customHeader, title }: Props) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <div className="flex items-center justify-center absolute top-0 right-0 left-0 bottom-0 p-0 md:p-4 z-100 h-screen">
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-artichoke-100 opacity-80 z-50 h-full"></div>
      <div className="fixed bg-silver-40 md:rounded-xl z-50 w-full lg:w-auto h-full md:h-auto overflow-y-auto max-h-screen">
        {customHeader && customHeader}
        {!customHeader && (
          <>
            <div className="flex px-6 py-2 w-full">
              <div className="text-charcoal-100 text-center flex-1 text-lg">{title}</div>
              <div onClick={() => setIsOpen(false)} className="flex-shrink">
                <Icon icon="cross" className="hover:cursor-pointer" />
              </div>
            </div>
          </>
        )}
        <div className={cn('px-6 pb-3', { 'pt-3': !title })}>{children}</div>
      </div>
    </div>
  );
};
