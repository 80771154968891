import { z } from 'zod';

export const ResultStatusSchema = z.union([z.literal('NOT_RECEIVED'), z.literal('RECEIVED_NOT_RELEASED'), z.literal('RECEIVED_RELEASED')]);
export const ReportTypeSchema = z.union([z.literal('EGG_COUNT_REPORT'), z.literal('HEALTH_PRACTITIONER_REPORT'), z.literal('APPOINTMENT_NOTE')]);
export const ResultReferenceRangeSchema = z.union([z.literal('PERCENTILE_2_5'), z.literal('LOW'), z.literal('IN_RANGE'), z.literal('HIGH')]);

export const ResultAgeGroupSchema = z.union([z.literal('25-29'), z.literal('30-34'), z.literal('35-39'), z.literal('40-44'), z.literal('45-50')]);

const ResultReferenceRangeValuesSchema = z.object({
  PERCENTILE_2_5: z.number(),
  LOW: z.number(),
  HIGH: z.number(),
});

const ResultReportSchema = z.object({
  ageGroup: ResultAgeGroupSchema,
  referenceRange: ResultReferenceRangeSchema,
  range: ResultReferenceRangeValuesSchema,
  notes: z.array(z.string()).default([]),
});

export const ResultSchema = z.object({
  code: z.string(),
  abbreviation: z.string(),
  name: z.string(),
  result: z.number(),
  units: z.string(),
  report: ResultReportSchema.optional(),
});

export type TestKitResult = z.infer<typeof ResultSchema>;
export type TestKitResultReport = z.infer<typeof ResultReportSchema>;
export type TestKitResultReferenceRange = z.infer<typeof ResultReferenceRangeSchema>;
export type TestKitResultReferenceRangeValues = z.infer<typeof ResultReferenceRangeValuesSchema>;
export type TestKitResultAgeGroup = z.infer<typeof ResultAgeGroupSchema>;
export type ReportType = z.infer<typeof ReportTypeSchema>;
