import React, { useEffect, useState } from 'react';
import { Button } from '@when-fertility/shared/domain/common';
import { useUserProfile } from '../../user';
import { QuizQuestion } from './quiz-question.component';

export const Quiz = () => {
  const { next } = useUserProfile();
  const { currentQuestion } = useUserProfile();
  const [showNextButton, setShowNextButton] = useState(true);
  useEffect(() => {
    currentQuestion.type === 'select' ? setShowNextButton(false) : setShowNextButton(true);
  }, [currentQuestion]);

  return (
    <div className="flex flex-col justify-between w-full md:w-96 pt-20">
      <QuizQuestion setShowNextButton={setShowNextButton} />
      <div className="flex flex-col mb-[70px] sm:mb-[60px]">
        {showNextButton && (
          <Button onClick={next} className="mt-2">
            Next
          </Button>
        )}
        <div className={'text-center'}>
          <button onClick={next} className="mt-2 border-0 underline text-charcoal-60">
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};
